import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { Post, Get } from '../../Layout/ajax';
import { CModal } from '@coreui/react';
import * as Yup from "yup";
import Swal from "sweetalert2";
import moment from "moment";
import { immediateToast } from "izitoast-react";
import { Bookmark } from 'react-feather';
import { current } from '@reduxjs/toolkit';
import { ButtonGroup } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import Pagination from "react-js-pagination";
import { UserModal} from './Modal.js';

const validation = Yup.object().shape({
    UserId: Yup.string().required(),
    Punchin_time: Yup.string().required(),
    Punchout_time: Yup.string().required(),
});
const Attendance = () => {
    const [initValue, setinitValue] = useState({
        UserId: "",
        Punchin_time: "",
        Punchout_time: "",
    });
    const [data, setData] = useState([]);
    const [Attendanceadd, setAttendanceadd] = useState(false);
    const [Attendance, setAttendance] = useState(0);
    const [user, setuser] = useState([]);
    const [useradd, setuseradd] = useState(false);
    const [isGridView, setIsGridView] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNo, setPageNo] = useState(1);
    const [perPage, setPerPage] = useState(6);
    const [filtername, setfiltername] = useState("");
    const showTost = (msg, theme) => {
        immediateToast(theme, {
            message: msg,
            timeout: 3000
        });
    }
    useEffect(() => {
        getpost();
        getuserpost();
    }, []);
    const NexPage = (NexPage) => {
        setPageNo(NexPage);
    };
    useEffect(() => {
        getpost();
    }, [pageNo, perPage, filtername]);
    const getuserpost = async () => {
        Post(`users/getAllUser`, { active: true })
            .then((res) => {
                setuser(res)
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const getpost = async () => {
        let skipa = perPage * (pageNo - 1);
        await Post(`attendance/getAllAttendance`, { skip: skipa, per_page: perPage, filter: filtername })
            .then((res) => {
                setData(res.data.data)
                setTotalCount(res.data.count);
            })
            .catch((err) => {
                setTotalCount(0);
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    } 
    const attendancebyid = async (val) => {
        if (val != 0) {
            Get(`attendance/getAttendanceById/${val}`)
                .then((res) => {
                    setinitValue({
                        UserId: res.data[0].UserId?._id,
                        Punchin_time: moment(res.data[0].Punchin_time).format("YYYY-MM-DDTHH:mm"),
                        Punchout_time: moment(res.data[0].Punchout_time).format("YYYY-MM-DDTHH:mm"),
                    })
                    setAttendanceadd(true);
                    setAttendance(val);
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    };
    const deleteAttendance = async (val) => {
        Swal.fire({
            title: "Are you sure want to delete ?",
            icon: "error",
            confirmButtonText: "Delete",
            confirmButtonColor: "#FD711A",
            showCancelButton: true,
            cancelButtonColor: "#0F3E6A",
        }).then(async ({ isConfirmed }) => {
            if (isConfirmed) {
                await Post(
                    `attendance/deleteAttendanceById/${val}`
                )
                    .then(res => {
                        if (res.success == false) {
                            showTost(res.msg, "warning");
                        } else {
                            getpost();
                        }
                    })
                    .catch((err) => {
                        showTost("SOMETHING WENT WRONG ON SERVER", "error");
                    });
            }
        });
    };
    const clear = () => {
        setinitValue({
            Punchin_time: "",
            UserId: "",
            Punchout_time: "",
        });
    }
    const handleSubmit = async (val) => {
        if (Attendance == 0) {
            await Post(`attendance/addAttendance`, val)
                .then((res) => {
                    if (res.success == true) {
                        clear();
                        setAttendanceadd(false);
                        Swal.fire({
                            title: "Your work has been saved",
                            icon: "success",
                            confirmButtonText: "OK",
                            confirmButtonColor: "#FD711A",
                        }).then(async ({ isConfirmed }) => {
                            if (isConfirmed) {
                                getpost();
                            }
                        });
                    }
                    else {
                        showTost(res.message, "warning");
                    }
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
        else {
            val.id = Attendance;
            await Post(`attendance/editAttendance`, val)
                .then((res) => {
                    if (res.success == true) {
                        clear();
                        setAttendanceadd(false);
                        Swal.fire({
                            title: "Your work has been saved",
                            icon: "success",
                            confirmButtonText: "OK",
                            confirmButtonColor: "#FD711A",
                        }).then(async ({ isConfirmed }) => {
                            if (isConfirmed) {
                                getpost();
                            }
                        });
                    }
                    else {
                        showTost(res.message, "warning");
                    }
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    };
    return (
        <div className='content-wrapper page'>
            <div className="row">
                <div className="mb-2 mt-2 row col-12">
                    <div className="col-2">
                        <h2>Attendance</h2>
                    </div>
                    <div className="col-10 mt-md-0 mt-2">
                        <div className="float-right ">
                            <div className='d-flex mb-2'>
                                <span className='btn btn-sm btn-primary ml-4 mr-2' onClick={() => { setAttendanceadd(true); clear(); setAttendance(0); }}>
                                    <i className="fa fa-plus mr-2" /> Add
                                </span>
                                <span>
                                    <ButtonGroup>
                                        <Btn size="sm" color={isGridView ? "outline-info" : "info"} onClick={() => setIsGridView(false)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-grid"><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg>
                                        </Btn>
                                        <Btn size="sm" color={!isGridView ? "outline-info" : "info"} onClick={() => setIsGridView(true)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-list"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3" y2="6"></line><line x1="3" y1="12" x2="3" y2="12"></line><line x1="3" y1="18" x2="3" y2="18"></line></svg>
                                        </Btn>
                                    </ButtonGroup>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-md-0 mt-2">
                        <div className="float-right ">
                            <div className='d-flex mb-2'>
                                <input type="text" placeholder="Search User Name" onKeyPress={(event) => {
                                    if (event.nativeEvent.key === 'Enter') {
                                        setfiltername(event.target.value)
                                    }
                                }} className="form-control w-auto" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='bookmark-tabcontent'>
                <div className={"details-bookmark text-center " + (isGridView ? "list-bookmark" : '')} id="list-bookmark1">
                    <div className="row" id="bookmarkData">
                        {data.length == 0 ? <div className="mt-5">
                            No Record Found
                        </div> : data.map(item => (
                            <div className="col-xl-3 col-md-4 xl-50">
                                <div className="card card-with-border bookmark-card o-hidden">
                                    <div className="details-website">
                                        <div className="desciption-data">
                                            <div className="title-bookmark">
                                                <span className="weburl_0"><i className="fa fa-user text-danger mr-2" />{item.UserId?.name}</span>
                                                <div className="d-flex">
                                                    <span className=' ml-auto'> <i className='btn-sm btn btn-success fa fa-pencil mr-2' onClick={() => attendancebyid(item._id)} /><i className='fa fa-trash btn btn-sm btn-danger' onClick={() => { deleteAttendance(item._id); }} /></span>
                                                </div>
                                                <div>
                                                    <span className="d-flex"><i className="fa fa-calendar text-success mr-2" /><label className='label2 mr-2' >Punch In:  </label>{moment(item.Punchin_time).format("DD-MMM-YYYY hh:mm a")}<i className="fa fa-clock-o text-success mt-1 ml-auto" /></span>
                                                </div>
                                                <div>
                                                    <span className="d-flex"><i className="fa fa-calendar text-danger mr-2" /><label className='label1 mr-2'>Punch Out: </label>{moment(item.Punchout_time).format("DD-MMM-YYYY hh:mm a")}<i className="fa fa-clock-o text-danger ml-auto" /></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {data.length == 0 ? "" :
                        <Pagination
                            activePage={pageNo}
                            itemsCountPerPage={perPage}
                            totalItemsCount={totalCount}
                            pageRangeDisplayed={5}
                            linkclassName={"page-link"}
                            onChange={NexPage}
                            itemClass={"page-item"}
                            linkClass={"page-link"}
                        />
                    }
                </div>
            </div>


            <CModal alignment="center" visible={Attendanceadd}>
                <div className="modal-header">
                    <h4 className="modal-title">{Attendance == 0 ? "Add Attendance" : "Update Attendance"}</h4>
                    <button type="button" className="close" onClick={() => { setAttendanceadd(false) }}>&times;</button>
                </div>
                <div className="modal-body">
                    <Formik
                        onSubmit={handleSubmit}
                        initialValues={initValue}
                        validationSchema={validation}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div className="col-12 mb-2">
                                        <div className="row">
                                            <div className="col-6 mt-2">
                                                <label><i className="fa fa-user text-warning mr-2" />User</label>
                                                <div className='input-group'>
                                                    <select defaultValue={values.UserId} className={!!touched.UserId && !!errors.UserId ? "form-select is-invalid" : "form-select"} name='UserId'>
                                                        <option value=''>Select User</option>
                                                        {user
                                                            .map((x, i) => (
                                                                <option key={i} value={x._id}>{x.name}</option>
                                                            ))}
                                                    </select>
                                                    <span className="input-group-text">
                                                        <i className='fa fa-plus' onClick={() => setuseradd(true)} />
                                                        <UserModal 
                                                            useradd={useradd}
                                                            setuseradd={setuseradd}
                                                            getuserpost={getuserpost}
                                                            showTost={showTost}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-6 mt-2"></div>
                                            <div className="col-6 mt-2">
                                                <label><i className="fa fa-calendar text-warning mr-2" />Punch-In Time</label>
                                                <input type="datetime-local" placeholder="Start Date" defaultValue={values.Punchin_time} name='Punchin_time' className={!!touched.Punchin_time && !!errors.Punchin_time ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className="col-6 mt-2">
                                                <label><i className="fa fa-calendar text-warning mr-2" />Punch-Out Time</label>
                                                <input type="datetime-local" placeholder="End Date" defaultValue={values.Punchout_time} name='Punchout_time' className={!!touched.Punchout_time && !!errors.Punchout_time ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='mt-2 col-12'>
                                                <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </CModal>
        </div>
    );
}

export default Attendance;