import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { Post, Get } from '../../Layout/ajax';
import { CModal } from '@coreui/react';
import { immediateToast } from "izitoast-react";
import { ButtonGroup } from 'reactstrap';
import Select from "react-select";
import { Btn } from '../../AbstractElements';
import * as Yup from "yup";
import moment from "moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Swal from "sweetalert2";
import Pagination from "react-js-pagination";
import { CustomerModal } from './Modal.js';

const validation = Yup.object().shape({
    customer: Yup.string().required(),
    amount: Yup.string().required(),
    reminder: Yup.string().required(),
    nextfollowup: Yup.string().required(),
    note: Yup.string().required(),
});
const Recovery = () => {
    const $ = window.$;
    const [initValue, setinitValue] = useState({
        customer: "",
        amount: "",
        reminder: "",
        nextfollowup: "",
        note: "",
    });
    const showTost = (msg, theme) => {
        immediateToast(theme, {
            message: msg,
            timeout: 3000
        });
    }
    const [data, setData] = useState([]);
    const [recoveryadd, setrecoveryadd] = useState(false);
    const [recovery, setrecovery] = useState(0);
    const [filterstatus, setfilterstatus] = useState("");
    const [customer, setcustomer] = useState([]);
    const [isGridView, setIsGridView] = useState(false);
    const [Manual, setManual] = useState(false);
    const [customeradd, setcustomeradd] = useState(false);
    const [filtername, setfiltername] = useState("");
    const [totalCount, setTotalCount] = useState(0);
    const [pageNo, setPageNo] = useState(1);
    const [perPage, setPerPage] = useState(6);
    const NexPage = (NexPage) => {
        setPageNo(NexPage);
    };
    useEffect(() => {
        getpost(true);
    }, [pageNo, perPage, filtername, filterstatus]);

    useEffect(() => {
        getcustomerpost();
        Get(`master/getApplicationSetting`)
            .then((res) => {
                setManual(res.data)
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }, []);

    const getcustomerpost = async () => {
        Post(`customer/getAllCustomer`, { active: true })
            .then((res) => {
                setcustomer(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }

    const getpost = async (val) => {
        let skipa = perPage * (pageNo - 1);
        await Post(`recovery/getAllRecovery`, { active: val, status: filterstatus, skip: skipa, per_page: perPage, filter: filtername })
            .then((res) => {
                setData(res.data.data)
                setTotalCount(res.data.count);
            })
            .catch((err) => {
                setTotalCount(0);
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }

    const clear = () => {
        setinitValue({
            customer: "",
            amount: "",
            reminder: "",
            nextfollowup: "",
            note: "",
        });
    }

    const getbyid = async (val) => {
        if (val != 0) {
            Get(`recovery/getRecoveryById/${val}`)
                .then((res) => {
                    setinitValue({
                        customer: res.data[0].Customer?._id,
                        amount: res.data[0].Amount,
                        reminder: moment(res.data[0].Reminder).format("YYYY-MM-DD"),
                        nextfollowup: moment(res.data[0].NextFollowup).format("YYYY-MM-DD"),
                        note: res.data[0].Note,
                    });
                    setrecoveryadd(true);
                    setrecovery(val);
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    }

    const handleSubmit = async (val) => {
        await Post(`recovery/addRecovery`, val)
            .then((res) => {
                if (res.success == false) {
                    showTost(res.message, "warning");
                }
                else {
                    clear();
                    setrecoveryadd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getpost(true);
                        }
                    });
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };

    const handleEdit = async (val) => {
        val.id = recovery;
        await Post(`recovery/editRecovery`, val)
            .then((res) => {
                if (res.success == true) {
                    clear();
                    setrecoveryadd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getpost(true);
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };

    const complateRecovery = async (val) => {
        await Post(`recovery/complateRecovery/${val}`)
            .then((res) => {
                if (res.success == true) {
                    getpost(true);
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const deleteRecovery = async (val) => {
        Swal.fire({
            title: "Are you sure want to delete ?",
            icon: "error",
            confirmButtonText: "Delete",
            confirmButtonColor: "#FD711A",
            showCancelButton: true,
            cancelButtonColor: "#0F3E6A",
        }).then(async ({ isConfirmed }) => {
            if (isConfirmed) {
                await Get(
                    `recovery/deleteRecovery/${val}`
                )
                    .then(res => {
                        if (res.success == false) {
                            showTost(res.msg, "warning");
                        } else {
                            getpost(true);
                        }
                    })
                    .catch((err) => {
                        showTost("SOMETHING WENT WRONG ON SERVER", "error");
                    });
            }
        });
    };
    return (
        <div className='content-wrapper'>
            <div className="row ">
                <div className="mb-2 mt-2 row col-12">
                    <div className="col-2">
                        <h2>Recovery</h2>
                    </div>
                    <div className="col-10">
                        <div className="float-right">
                            <div className='d-flex mb-2'>
                                <ReactHTMLTableToExcel
                                    id="test-table-xls-button"
                                    className="download-table-xls-button btn btn-sm btn-warning mr-2"
                                    table="table-to-xls"
                                    title="Export to Excel"
                                    filename="Recovery"
                                    sheet="tablexls"
                                    buttonText={<i className="fa fa-file-excel-o" />}
                                />
                                <span className='btn btn-sm btn-primary mr-2' onClick={() => { setrecoveryadd(true); setrecovery(0); }}>
                                    <i className="fa fa-plus " /> Add Recovery
                                </span>

                                <span>
                                    <ButtonGroup>
                                        <Btn size="sm" color={isGridView ? "outline-info" : "info"} onClick={() => setIsGridView(false)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-grid"><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg>
                                        </Btn>
                                        <Btn size="sm" color={!isGridView ? "outline-info" : "info"} onClick={() => setIsGridView(true)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-list"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3" y2="6"></line><line x1="3" y1="12" x2="3" y2="12"></line><line x1="3" y1="18" x2="3" y2="18"></line></svg>
                                        </Btn>
                                    </ButtonGroup>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="float-right">
                            <div className="input-group">
                                <input type="text" placeholder="Search Company Name" onKeyPress={(event) => {
                                    if (event.nativeEvent.key === 'Enter') {
                                        setfiltername(event.target.value)
                                    }
                                }} className="form-control mr-2 w-auto" />
                                <select className="form-select w-auto" onChange={(e) => setfilterstatus(e.target.value)}>
                                    <option value="">Select Status</option>
                                    <option value="In Complete">In Complete</option>
                                    <option value="Completed">Completed</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bookmark-tabcontent'>
                <div className={"details-bookmark text-center " + (isGridView ? "list-bookmark" : '')} id="list-bookmark1">
                    <div className="row" id="bookmarkData">
                        {data.length == 0 ? <div className="mt-5">
                            No Record Found
                        </div> : data.map(item => (
                            <div className="col-xl-3 col-md-4 xl-50">
                                <div className="card card-with-border bookmark-card o-hidden">
                                    <div className="details-website">
                                        <div className="desciption-data">
                                            <div className="title-bookmark">
                                                <h6><i className="fa fa-building mr-2" />{item.Customer?.Company}</h6>
                                                <div className="d-flex">
                                                    <span className='ml-auto'>{item.is_active == false ? "" : <><i className='btn btn-sm btn-success fa fa-pencil mr-1' onClick={() => { getbyid(item._id) }} /><i className='btn btn-sm btn-danger fa fa-trash' onClick={() => { deleteRecovery(item._id); }} /></>}</span>
                                                </div>
                                                <div>
                                                    <span><i className="fa fa-user text-danger mr-2" />{item.Customer?.FirstName} {item.Customer?.LastName} </span>
                                                </div>
                                                <div>
                                                    <span><label className='label2 mr-2' >Recovery No:  </label>{item.RecoveryNo}</span>
                                                </div>
                                                <div>
                                                    <span><i className="fa fa-rupee text-success mr-2" />{item.Amount}</span>
                                                </div>
                                                <div>
                                                    <span><i className="fa fa-calendar text-success mr-2" /><label className='label3 mr-2'>Next Followup: </label> {moment(item.NextFollowup).format("DD-MMM-YYYY")}</span>
                                                </div>
                                                <div>
                                                    <span><label className='label5 mr-2'>Recovery Status:</label>{item.Status == "In Complete" ? <button className='btn btn-sm btn-secondary' onClick={() => complateRecovery(item._id)}>Complate</button> : <i className="text-success pl-4 fa fa-check-circle" style={{ fontSize: 25 }} />}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}


                    </div>
                    {data.length == 0 ? "" :
                        <Pagination
                            activePage={pageNo}
                            itemsCountPerPage={perPage}
                            totalItemsCount={totalCount}
                            pageRangeDisplayed={5}
                            linkclassName={"page-link"}
                            onChange={NexPage}
                            itemClass={"page-item"}
                            linkClass={"page-link"}
                        />
                    }
                </div>
            </div>
            <table id="table-to-xls" style={{ display: 'none' }}>
                <thead>
                    <tr>
                        <th>RecoveryNo</th>
                        <th>Company</th>
                        <th>Contact</th>
                        <th>Amount</th>
                        <th>Reminder (Last)</th>
                        <th>Next Followup</th>
                        <th>Internal Notes</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map(item => (
                        <tr>
                            <td>{item.RecoveryNo}</td>
                            <td>{item.Customer?.Company}</td>
                            <td>{item.Customer?.FirstName} {item.Customer?.LastName}</td>
                            <td>{item.Amount}</td>
                            <td>{item.Reminder == null ? "-" : moment(item.Reminder).format("DD-MMM-YYYY")}</td>
                            <td>{item.NextFollowup == null ? "-" : moment(item.NextFollowup).format("DD-MMM-YYYY")}</td>
                            <td>{item.Note}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <CModal alignment="center" size="lg" visible={recoveryadd}>
                <div className="modal-header">
                    <h4 className="modal-title">{recovery == 0 ? "Add Recovery" : "Edit Recovery"}</h4>
                    <button type="button" className="close" onClick={() => { clear(); setrecoveryadd(false) }}>&times;</button>
                </div>
                <div className="modal-body">
                    <Formik
                        onSubmit={recovery == 0 ? handleSubmit : handleEdit}
                        initialValues={initValue}
                        validationSchema={validation}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div className="col-12 mb-2">
                                        <div className="row">
                                            <div className='col-12 mt-2'>
                                                <label>Customer</label>
                                                <div className='input-group'>
                                                    <Select
                                                        name="customer"
                                                        value={customer.map((x) => ({
                                                            value: x._id,
                                                            label: `${x.Company} (${x.FirstName} ${x.LastName})`,
                                                        })).find((option) => option.value === values.customer) || null}
                                                        onChange={(e) => {
                                                            setFieldValue("customer", e.value);
                                                        }}
                                                        options={customer.map((x) => ({
                                                            value: x._id,
                                                            label: `${x.Company} (${x.FirstName} ${x.LastName})`,
                                                        }))}
                                                        className={touched.customer && errors.customer ? "is-invalid w-75" : "w-75"}
                                                    />
                                                    <span className="input-group-text">
                                                        <i className='fa fa-plus' onClick={() => setcustomeradd(true)} />
                                                        <CustomerModal
                                                            setcustomeradd={setcustomeradd}
                                                            customeradd={customeradd}
                                                            getcustomerpost={getcustomerpost}
                                                            showTost={showTost}
                                                            Manual={Manual}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='col-6 mt-2'>
                                                <label>Amount</label>
                                                <input type='text' name="amount" defaultValue={values.amount} placeholder='Enter Amount' className={!!touched.amount && !!errors.amount ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='col-6 mt-2'>
                                                <label>Recovery reminder (Last sent)</label>
                                                <input type='date' name="reminder" max={new Date().getFullYear() + '-' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '-' + ("0" + new Date().getDate()).slice(-2)} defaultValue={values.reminder} placeholder='Enter Reminder' className={!!touched.reminder && !!errors.reminder ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='col-6 mt-2'>
                                                <label>Next Followup</label>
                                                <input type='date' name="nextfollowup" min={new Date().getFullYear() + '-' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '-' + ("0" + new Date().getDate()).slice(-2)} defaultValue={values.nextfollowup} placeholder='Enter Next Followup' className={!!touched.nextfollowup && !!errors.nextfollowup ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='col-12 mt-2'>
                                                <label>Note</label>
                                                <textarea type='text' rows="3" name="note" defaultValue={values.note} placeholder='Enter Note' className={!!touched.note && !!errors.note ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='col-12 mt-2'>
                                                <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </CModal>
        </div>
    );
}

export default Recovery;