import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { Post, Get, FileUpload } from '../../Layout/ajax';
import { CModal, CImage } from '@coreui/react';
import { immediateToast } from "izitoast-react";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { FileUploader } from "react-drag-drop-files";
import CKEditor from 'react-ckeditor-component';

const validation = Yup.object().shape({
  CompanyTitle: Yup.string().required(),
  OfficeAddress: Yup.string().required(),
  OfficeEmail: Yup.string().email().required(),
  BankName: Yup.string().required(),
  AccNo: Yup.string().required(),
  IFSCNo: Yup.string().required(),
  OfficePhone1: Yup.string().max(10).min(10).required().matches(/^[+]?[0-9]+$/),
});
const ApplicationSetting = () => {
  const [initValue, setinitValue] = useState({
    CompanyTitle: "",
    CompanySubTitle: "",
    BankName: "",
    AccNo: "",
    IFSCNo: "",
    Quotation: false,
    QuotationPrefix: "",
    QuotationSuffix: "",
    Invoice: false,
    InvoicePrefix: "",
    InvoiceSuffix: "",
    Ticket: false,
    TicketPrefix: "",
    TicketSuffix: "",
    Customer: false,
    CustomerPrefix: "",
    CustomerSuffix: "",
    Order: false,
    OrderPrefix: "",
    OrderSuffix: "",
    TermsAndCondition: "",
    OfficeAddress: "",
    OfficeEmail: "",
    PanNo: "",
    GSTNo: "",
    RegisterNo: "",
    OfficePhone1: "",
    OfficePhone2: "",
    IndiaMartKey: "",
    JustDialKey: ""
  });
  const [ID, setID] = useState([]);
  const [selectedFile, setFile] = useState(null);

  const [preview, setPreview] = useState(null);
  useEffect(() => {
    getpost();
  }, []);
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined)
      return
    }
    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  const handleChange1 = (image) => {
    setFile(image);
  };
  const showTost = (msg, theme) => {
    immediateToast(theme, {
      message: msg,
      timeout: 3000
    });
  }
  const getpost = async (val) => {
    await Get(`master/getApplicationSetting`)
      .then((res) => {
        setinitValue({
          CompanyTitle: res.data.CompanyTitle,
          CompanySubTitle: res.data.CompanySubTitle,
          BankName: res.data.BankName,
          AccNo: res.data.AccNo,
          IFSCNo: res.data.IFSCNo,
          Quotation: res.data.Quotation,
          QuotationPrefix: res.data.QuotationPrefix,
          QuotationSuffix: res.data.QuotationSuffix,
          Invoice: res.data.Invoice,
          InvoicePrefix: res.data.InvoicePrefix,
          InvoiceSuffix: res.data.InvoiceSuffix,
          Ticket: res.data.Ticket,
          TicketPrefix: res.data.TicketPrefix,
          TicketSuffix: res.data.TicketSuffix,
          Customer: res.data.Customer,
          CustomerPrefix: res.data.CustomerPrefix,
          CustomerSuffix: res.data.CustomerSuffix,
          Order: res.data.Order,
          OrderPrefix: res.data.OrderPrefix,
          OrderSuffix: res.data.OrderSuffix,
          TermsAndCondition: res.data.TermsAndCondition,
          OfficeAddress: res.data.OfficeAddress,
          OfficeEmail: res.data.OfficeEmail,
          PanNo: res.data.PanNo,
          GSTNo: res.data.GSTNo,
          RegisterNo: res.data.RegisterNo,
          OfficePhone1: res.data.OfficePhone1,
          OfficePhone2: res.data.OfficePhone2,
          IndiaMartKey: res.data.IndiaMartKey,
          JustDialKey: res.data.JustDialKey
        });
        setPreview(process.env.REACT_APP_APIURL + 'static/' + res.data.CompanyLogo);
        setID(res.data._id)
      })
      .catch((err) => {
        showTost("SOMETHING WENT WRONG ON SERVER", "error");
      });
  }
  const handleSubmit = async (val) => {
    let formdata = new FormData();
    formdata.append("CompanyLogo", selectedFile);
    formdata.append("Id", ID);
    formdata.append("CompanyTitle", val.CompanyTitle);
    formdata.append("BankName", val.BankName);
    formdata.append("AccNo", val.AccNo);
    formdata.append("IFSCNo", val.IFSCNo);
    formdata.append("CompanySubTitle", val.CompanySubTitle);
    formdata.append("Quotation", val.Quotation);
    formdata.append("QuotationPrefix", val.QuotationPrefix);
    formdata.append("QuotationSuffix", val.QuotationSuffix);
    formdata.append("Invoice", val.Invoice);
    formdata.append("InvoicePrefix", val.InvoicePrefix);
    formdata.append("InvoiceSuffix", val.InvoiceSuffix);
    formdata.append("Ticket", val.Ticket);
    formdata.append("TicketPrefix", val.TicketPrefix);
    formdata.append("TicketSuffix", val.TicketSuffix);
    formdata.append("Customer", val.Customer);
    formdata.append("CustomerPrefix", val.CustomerPrefix);
    formdata.append("CustomerSuffix", val.CustomerSuffix);
    formdata.append("Order", val.Order);
    formdata.append("OrderPrefix", val.OrderPrefix);
    formdata.append("OrderSuffix", val.OrderSuffix);
    formdata.append("TermsAndCondition", val.TermsAndCondition);
    formdata.append("OfficeAddress", val.OfficeAddress);
    formdata.append("OfficeEmail", val.OfficeEmail);
    formdata.append("PanNo", val.PanNo);
    formdata.append("GSTNo", val.GSTNo);
    formdata.append("RegisterNo", val.RegisterNo);
    formdata.append("OfficePhone1", val.OfficePhone1);
    formdata.append("OfficePhone2", val.OfficePhone2);
    formdata.append("IndiaMartKey", val.IndiaMartKey);
    formdata.append("JustDialKey", val.JustDialKey);
    await FileUpload(`master/addApplicationSetting`, formdata)
      .then((res) => {
        if (res.success == true) {
          Swal.fire({
            title: "Your work has been saved",
            icon: "success",
            confirmButtonText: "OK",
            confirmButtonColor: "#FD711A",
          }).then(async ({ isConfirmed }) => {
            if (isConfirmed) {
              getpost();
            }
          });
        }
        else {
          showTost(res.message, "warning");
        }
      })
      .catch((err) => {
        showTost("SOMETHING WENT WRONG ON SERVER", "error");
      });
  };
  return <>
    <div className='content-wrapper'>
      <div className="row ">
        <div className="mb-2 mt-2 row col-12">
          <div className="col-10">
            <h2>Application Setting</h2>
          </div>
        </div>
        <div className="col-12 mt-3">
          <Formik
            enableReinitialize
            onSubmit={handleSubmit}
            initialValues={initValue}
            validationSchema={validation}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              touched,
              errors,
              setFieldValue,
            }) => {
              return (
                <Form
                  onSubmit={handleSubmit}
                  onChange={handleChange}>
                  <div className='row'>
                    <div className='col-6 mb-2'>
                      <label>Company Title</label>
                      <input type='text' name="CompanyTitle" value={values.CompanyTitle} className={!!touched.CompanyTitle && !!errors.CompanyTitle ? "form-control is-invalid" : "form-control"} />
                    </div>
                    <div className='col-6 mb-2'>
                      <label>Company SubTitle</label>
                      <input type='text' name="CompanySubTitle" value={values.CompanySubTitle} className={!!touched.CompanySubTitle && !!errors.CompanySubTitle ? "form-control is-invalid" : "form-control"} />
                    </div>
                    <div className='col-8'>
                      <label>Upload Company Logo</label>
                      <FileUploader handleChange={handleChange1} multiple={false} name="image" types={["JPEG", "JPG", "PNG"]} />
                    </div>
                    <div className='col-4 mt-2'>
                      {preview && <CImage rounded thumbnail src={preview} width={100} height={100} />}
                    </div>
                    <div className='col-4 mb-2'>
                      <label>Bank Name</label>
                      <input type='text' name="BankName" value={values.BankName} className={!!touched.BankName && !!errors.BankName ? "form-control is-invalid" : "form-control"} />
                    </div>
                    <div className='col-4 mb-2'>
                      <label>IFSC No.</label>
                      <input type='text' name="IFSCNo" value={values.IFSCNo} className={!!touched.IFSCNo && !!errors.AccNo ? "form-control is-invalid" : "form-control"} />
                    </div>
                    <div className='col-4 mb-2'>
                      <label>Account No.</label>
                      <input type='text' name="AccNo" value={values.AccNo} className={!!touched.AccNo && !!errors.AccNo ? "form-control is-invalid" : "form-control"} />
                    </div>
                    <div className='col-4 mb-2'>
                      <label>Quotation Manual Add</label>
                      <input type="checkbox" className='mt-2 ml-3' value={values.Quotation} name='Quotation' checked={values.Quotation == true ? "checked" : ""} />
                    </div>
                    <div className='col-4 mb-2'>
                      <label>Quotation Prefix</label>
                      <input type='text' name="QuotationPrefix" value={values.QuotationPrefix} className={!!touched.QuotationPrefix && !!errors.QuotationPrefix ? "form-control is-invalid" : "form-control"} />
                    </div>
                    <div className='col-4 mb-2'>
                      <label>Quotation Suffix</label>
                      <input type='text' name="QuotationSuffix" value={values.QuotationSuffix} className={!!touched.QuotationSuffix && !!errors.QuotationSuffix ? "form-control is-invalid" : "form-control"} />
                    </div>
                    <div className='col-4 mb-2'>
                      <label>Invoice Manual Add</label>
                      <input type="checkbox" className='mt-2 ml-3' value={values.Invoice} name='Invoice' checked={values.Invoice == true ? "checked" : ""} />
                    </div>
                    <div className='col-4 mb-2'>
                      <label>Invoice Prefix</label>
                      <input type='text' name="InvoicePrefix" value={values.InvoicePrefix} className={!!touched.InvoicePrefix && !!errors.InvoicePrefix ? "form-control is-invalid" : "form-control"} />
                    </div>
                    <div className='col-4 mb-2'>
                      <label>Invoice Suffix</label>
                      <input type='text' name="InvoiceSuffix" value={values.InvoiceSuffix} className={!!touched.InvoiceSuffix && !!errors.InvoiceSuffix ? "form-control is-invalid" : "form-control"} />
                    </div>
                    <div className='col-4 mb-2'>
                      <label>Order Manual Add</label>
                      <input type="checkbox" className='mt-2 ml-3' value={values.Order} name='Order' checked={values.Order == true ? "checked" : ""} />
                    </div>
                    <div className='col-4 mb-2'>
                      <label>Order Prefix</label>
                      <input type='text' name="OrderPrefix" value={values.OrderPrefix} className={!!touched.OrderPrefix && !!errors.OrderPrefix ? "form-control is-invalid" : "form-control"} />
                    </div>
                    <div className='col-4 mb-2'>
                      <label>Order Suffix</label>
                      <input type='text' name="OrderSuffix" value={values.OrderSuffix} className={!!touched.OrderSuffix && !!errors.OrderSuffix ? "form-control is-invalid" : "form-control"} />
                    </div>
                    <div className='col-4 mb-2'>
                      <label>Ticket Manual Add</label>
                      <input type="checkbox" className='mt-2 ml-3' value={values.Ticket} name='Ticket' checked={values.Ticket == true ? "checked" : ""} />
                    </div>
                    <div className='col-4 mb-2'>
                      <label>Ticket Prefix</label>
                      <input type='text' name="TicketPrefix" value={values.TicketPrefix} className={!!touched.TicketPrefix && !!errors.TicketPrefix ? "form-control is-invalid" : "form-control"} />
                    </div>
                    <div className='col-4 mb-2'>
                      <label>Ticket Suffix</label>
                      <input type='text' name="TicketSuffix" value={values.TicketSuffix} className={!!touched.TicketSuffix && !!errors.TicketSuffix ? "form-control is-invalid" : "form-control"} />
                    </div>
                    <div className='col-4 mb-2'>
                      <label>Customer Manual Add</label>
                      <input type="checkbox" className='mt-2 ml-3' value={values.Customer} name='Customer' checked={values.Customer == true ? "checked" : ""} />
                    </div>
                    <div className='col-4 mb-2'>
                      <label>Customer Prefix</label>
                      <input type='text' name="CustomerPrefix" value={values.CustomerPrefix} className={!!touched.CustomerPrefix && !!errors.CustomerPrefix ? "form-control is-invalid" : "form-control"} />
                    </div>
                    <div className='col-4 mb-2'>
                      <label>Customer Suffix</label>
                      <input type='text' name="CustomerSuffix" value={values.CustomerSuffix} className={!!touched.CustomerSuffix && !!errors.CustomerSuffix ? "form-control is-invalid" : "form-control"} />
                    </div>
                    <div className='col-12 mb-2'>
                      <label>Office Address</label>
                      <textarea type='text' name="OfficeAddress" rows="3" value={values.OfficeAddress} className={!!touched.OfficeAddress && !!errors.OfficeAddress ? "form-control is-invalid" : "form-control"} />
                    </div>
                    <div className='col-4 mb-2'>
                      <label>Pan No.</label>
                      <input type='text' name="PanNo" value={values.PanNo} className={!!touched.PanNo && !!errors.PanNo ? "form-control is-invalid" : "form-control"} />
                    </div>
                    <div className='col-4 mb-2'>
                      <label>GST No.</label>
                      <input type='text' name="GSTNo" value={values.GSTNo} className={!!touched.GSTNo && !!errors.GSTNo ? "form-control is-invalid" : "form-control"} />
                    </div>
                    <div className='col-4 mb-2'>
                      <label>Register No.</label>
                      <input type='text' name="RegisterNo" value={values.RegisterNo} className={!!touched.RegisterNo && !!errors.RegisterNo ? "form-control is-invalid" : "form-control"} />
                    </div>
                    <div className='col-6 mb-2'>
                      <label>Office PhoneNumber1</label>
                      <input type='text' name="OfficePhone1" value={values.OfficePhone1} className={!!touched.OfficePhone1 && !!errors.OfficePhone1 ? "form-control is-invalid" : "form-control"} />
                    </div>
                    <div className='col-6 mb-2'>
                      <label>Office PhoneNumber2</label>
                      <input type='text' name="OfficePhone2" value={values.OfficePhone2} className={!!touched.OfficePhone2 && !!errors.OfficePhone2 ? "form-control is-invalid" : "form-control"} />
                    </div>
                    <div className='col-6 mb-2'>
                      <label>Office Email</label>
                      <input type='email' name="OfficeEmail" value={values.OfficeEmail} className={!!touched.OfficeEmail && !!errors.OfficeEmail ? "form-control is-invalid" : "form-control"} />
                    </div>
                    <div className='col-6 mb-2'>
                      <label>India Mart</label>
                      <input type='text' name="IndiaMartKey" value={values.IndiaMartKey} className={!!touched.IndiaMartKey && !!errors.IndiaMartKey ? "form-control is-invalid" : "form-control"} />
                    </div>
                    <div className='col-6 mb-2'>
                      <label>Just Dial</label>
                      <input type='text' name="JustDialKey" value={values.JustDialKey} className={!!touched.JustDialKey && !!errors.JustDialKey ? "form-control is-invalid" : "form-control"} />
                    </div>
                    <div className='col-12 mb-2'>
                      <label>Terms And Conditions</label>
                      <CKEditor
                        name="TermsAndCondition"
                        id="TermsAndCondition"
                        content={values.TermsAndCondition}
                        events={{
                          change: (eve) => {
                            const data = eve.editor.getData();
                            setFieldValue("TermsAndCondition", data);
                          }
                        }} />
                    </div>
                    <div className='mt-2'>
                      <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div >
  </>;
}
export default ApplicationSetting;