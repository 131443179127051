import { configureStore } from "@reduxjs/toolkit";
import { BookMarkReducer } from './reducers/BookMarkReducer';
import { ThemeCustomizer } from './reducers/ThemeCustomizer';
import { RoleRight } from './reducers/RoleRight';

export const store = configureStore({
    reducer: {
        BookMarkReducer,
        ThemeCustomizer,
        RoleRight,
    },
})