import React, { useEffect, useState } from 'react';
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import axios from "axios";
import { Link } from 'react-router-dom';
import * as Yup from "yup";
import { immediateToast } from "izitoast-react";
import "izitoast-react/dist/iziToast.css";
import $ from 'jquery';
import "../Layout/login.css";
import styles from './Login.module.scss';
import { Btn, Spinner } from '../AbstractElements';

const validation = Yup.object().shape({
    email: Yup.string().required(),
    password: Yup.string().required(),
});

const initValue = {
    email: "",
    password: "",
};

const Login = () => {
    const [loading, setloading] = useState(false);

    useEffect(() => {
        $("#root").addClass('loginpage');
    }, []);

    const showTost = (msg, theme) => {
        immediateToast(theme, {
            message: msg,
            timeout: 3000
        });
    }

    const handleSubmit = async (val) => {
        setloading(true)
        setTimeout(async () => {
            await axios
                .post(`${process.env.REACT_APP_APIURL}api/users/login`, val)
                .then((res) => {
                    if (res.data.token !== undefined && res.data._id !== undefined) {
                        window.localStorage.setItem("Id", JSON.stringify(res.data._id));
                        window.localStorage.setItem("Token", JSON.stringify(res.data.token));
                        window.localStorage.setItem("Email", JSON.stringify(res.data.email));
                        window.localStorage.setItem("Role", JSON.stringify(res.data.role));
                        window.localStorage.setItem("RoleName", JSON.stringify(res.data.rolename));
                        window.localStorage.setItem("Name", JSON.stringify(res.data.name));
                        window.location.replace("/Taskboard")
                    }
                    else if (res.data.message == "User Not Found!") {
                        showTost("User Not Found!", "warning");
                    }
                })
                .catch((err) => {
                    showTost(err.response.data.message, "error");
                });
            setloading(false)
        }, 2000);
    };


    return (
        <Formik
            onSubmit={handleSubmit}
            initialValues={initValue}
            validationSchema={validation}
        >
            {({
                handleSubmit,
                handleChange,
                values,
                touched,
                errors,
                setFieldValue,
            }) => {
                return (
                    <section classn="ftco-section">
                        <div className="container loginform">
                            <div className="row justify-content-center">
                                <div className="col-md-7 col-lg-5 mt-5">
                                    <div className="wrap">
                                        <div className="login-wrap p-4 p-md-5">
                                            <div className="d-flex justify-content-center">
                                                <div className="row">
                                                    <div className="col-md-6 text-center mb-5">
                                                        <img className={`me-3 rounded-circle ${styles.fitimg}`} src="/../logo.jpg" alt=""></img>
                                                    </div>
                                                </div>
                                            </div>
                                            <form onSubmit={handleSubmit} className="signin-form">
                                                <div className="form-group mt-3 pb-3">
                                                    <input type="email" name='email' onChange={handleChange} className={!!touched.email && !!errors.email ? "form-control is-invalid" : "form-control"} />
                                                    <label className="form-control-placeholder">Email</label>
                                                </div>
                                                <div className="form-group">
                                                    <input id="password" name="password" type="password" onChange={handleChange} className={!!touched.password && !!errors.password ? "form-control is-invalid mt-2" : "form-control mt-2"} />
                                                    <label className="form-control-placeholder">Password</label>
                                                </div>
                                                <div className='text-end'>
                                                    <Link to={"/ForgotPassword"}>Forgot your password?</Link>
                                                </div>
                                                <div className="form-group">
                                                    {loading ?
                                                        <div className={`loader-box ${styles.Loader}`}>
                                                            <Spinner className={'loader-16'} />
                                                        </div>
                                                        :
                                                        <button type="submit" className="form-control btn btn-sm btn-primary rounded submit px-3" style={{ backgroundColor: "blue" }}>LOGIN</button>
                                                    }
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )
            }}
        </Formik>
    )
}

export default Login;