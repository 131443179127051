import React, { useEffect, useState, useRef } from 'react';
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { Post, Get, FileUpload } from '../../Layout/ajax';
import { CModal } from '@coreui/react';
import * as Yup from "yup";
import Swal from "sweetalert2";
import { immediateToast } from "izitoast-react";
import moment from "moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { Link } from 'react-router-dom';
import { SourceModal, ProductModal, UserModal } from './Modal.js';
import Select from "react-select";

const validation = Yup.object().shape({
    company: Yup.string().required(),
    // firstname: Yup.string().required(),
    email: Yup.string().email(),
    mobile: Yup.string().max(10).min(10).matches(/^[+]?[0-9]+$/),
    // city: Yup.string().required(),
    // state: Yup.string().required(),
    // country: Yup.string().required(),
    // sales: Yup.string().required(),
    // source: Yup.string().required(),
    // product: Yup.string().required(),
    stage: Yup.string().required(),
});
const validationcontact = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().email().required(),
    mobile: Yup.string().max(10).min(10).required().matches(/^[+]?[0-9]+$/),
});
const validationaddtask = Yup.object().shape({
    Name: Yup.string().required(),
    Priority: Yup.string().required(),
    Status: Yup.string().required(),
});
const validationnext = Yup.object().shape({
    date: Yup.string().required(),
    note: Yup.string().required(),
});
const validationremove = Yup.object().shape({
    reason: Yup.string().required(),
});
const Prospects = () => {
    const $ = window.$;
    const showTost = (msg, theme) => {
        immediateToast(theme, {
            message: msg,
            timeout: 3000
        });
    }
    const inputRef = useRef(null);
    const [sourceModal, setsourceModal] = useState(false);
    const [data, setData] = useState([]);
    const [Next, setNext] = useState([]);
    const [addprospect, setaddprospect] = useState(false);
    const [displayprospect, setdisplayprospect] = useState(false);
    const [prospect, setprospect] = useState(0);
    const [product, setproduct] = useState([]);
    const [sales, setsales] = useState([]);
    const [source, setsource] = useState([]);
    const [contacts, setcontacts] = useState(false);
    const [displayuser, setdisplayuser] = useState({});
    const [nextaction, setnextaction] = useState(false);
    const [filter, setfilter] = useState(false);
    const [deletes, setdeletes] = useState(false);
    const [movetocustomer, setmovetocustomer] = useState(false);
    const [productadd, setproductadd] = useState(false);
    const [State, setState] = useState([]);
    const [contactsdetails, setcontactsdetails] = useState([]);
    const [useradd, setuseradd] = useState(false);
    const [Status, setStatus] = useState([]);
    const [Country, setCountry] = useState([]);
    const [City, setCity] = useState([]);

    useEffect(() => {
        getsourcepost();
        getproductpost();
        getuserpost();
        Post(`master/getCountry`, { active: true })
            .then((res) => {
                setCountry(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        Post(`master/getState`, { active: true })
            .then((res) => {
                setState(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        Post(`master/getCity`, { active: true })
            .then((res) => {
                setCity(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        Post(`master/getStatus`, { active: true })
            .then((res) => {
                setStatus(res.data?.filter(f => f.GroupName == "Prospects"));
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }, []);
    useEffect(() => {
        getuserpost();
        getpost({ active: true, appointment: "all" });
    }, []);
    const getuserpost = async () => {
        Post(`users/getAllUser`, { active: true })
            .then((res) => {
                setsales(res);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const getsourcepost = async (val) => {
        await Post(`master/getSource`, { active: true })
            .then((res) => {
                setsource(res.data)
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const getproductpost = async (val) => {
        await Post(`master/getProduct`, { active: true })
            .then((res) => {
                setproduct(res.data)
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const getpost = async (val) => {
        await Post(`prospect/getAll`, val)
            .then((res) => {
                if (filter == true) {
                    setfilter(false);
                }
                if (res.success == false) {
                    showTost(res.msg, "warning")
                }
                else {
                    setData(res.data || []);
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const [initValue, setinitValue] = useState({
        company: "",
        gstno: "",
        title: "",
        firstname: "",
        lastname: "",
        mobile: "",
        email: "",
        city: "",
        state: "",
        country: "",
        source: "",
        CustomerRefrence: "",
        product: "",
        sales: "",
        address: "",
        requirements: "",
        prospectAmount: "",
        orderTarget: "",
        stage: "",
        notes: "",
    });
    const [initValuecontact, setinitValuecontact] = useState({
        name: "",
        email: "",
        mobile: "",
    });
    const [initValuenext, setinitValuenext] = useState({
        date: "",
        note: "",
    });
    const [initValueremove, setinitValueremove] = useState({
        reason: "",
    });
    const clearnext = () => {
        setinitValuenext({
            date: "",
            note: "",
            id: "",
            prospectid: ""
        });
    }
    const editnext = (val) => {
        setinitValuenext({
            id: val._id,
            date: moment(val.date).format("YYYY-MM-DD"),
            note: val.note,
        });
        setnextaction(true)
    }
    const contactclear = () => {
        setinitValuecontact({
            name: "",
            email: "",
            mobile: "",
        });
    }
    const savecontact = async (val) => {
        val.id = prospect;
        await Post(`prospect/addOtherContact`, val)
            .then((res) => {
                contactclear();
                $('.contactadd').hide()
                contactbyid(prospect);
                showTost("Your data has been Successfully added", "success");
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const contactbyid = async (val) => {
        if (val != 0) {
            Get(`prospect/otherContact/${val}`)
                .then((res) => {
                    setcontactsdetails(res.data);
                    setcontacts(true);
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    };
    const savenext = async (val) => {
        if (val.id) {
            await Post(`prospect/editNext`, val)
                .then((res) => {
                    if (res.success == false) {
                        showTost(res.msg, "warning");
                    }
                    else {
                        prospectbyid(prospect);
                        setnextaction(false)
                        clearnext();
                        showTost("Your data has been Successfully Updated", "success");
                    }
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
        else {
            val.prospectid = prospect;
            await Post(`prospect/addNext`, val)
                .then((res) => {
                    if (res.success == false) {
                        showTost(res.msg, "warning");
                    }
                    else {
                        prospectbyid(prospect);
                        setnextaction(false)
                        clearnext();
                        showTost("Your data has been Successfully added", "success");
                    }
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    }

    const importprospect = async (val) => {
        let formdata = new FormData();
        formdata.append("file", val.target.files[0]);
        await FileUpload(`prospect/importExcel`, formdata)
            .then((res) => {
                if (res.success == true) {
                    getpost({ active: true, appointment: "all" });
                }
                else {
                    showTost(res.msg, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const clear = () => {
        setinitValue({
            company: "",
            gstno: "",
            title: "",
            firstname: "",
            lastname: "",
            mobile: "",
            email: "",
            city: "",
            state: "",
            country: "",
            source: "",
            CustomerRefrence: "",
            product: "",
            sales: "",
            address: "",
            requirements: "",
            prospectAmount: "",
            orderTarget: "",
            stage: "",
            notes: "",
        });
    }
    const [datanewtask, setdatanewtask] = useState([]);
    const prospectbyid = async (val) => {
        if (val != 0) {
            Get(`prospect/${val}`)
                .then((res) => {
                    setinitValue({
                        prospectAmount: res.data.prospectList[0].ProspectAmount,
                        orderTarget: res.data.prospectList[0].OrderTarget,
                        stage: res.data.prospectList[0].Stage?._id,
                        company: res.data.prospectList[0].Company,
                        gstno: res.data.prospectList[0].GSTNo,
                        title: res.data.prospectList[0].Title,
                        firstname: res.data.prospectList[0].FirstName,
                        lastname: res.data.prospectList[0].LastName,
                        mobile: res.data.prospectList[0].Mobile,
                        email: res.data.prospectList[0].Email,
                        city: res.data.prospectList[0].City?._id,
                        state: res.data.prospectList[0].State?._id,
                        country: res.data.prospectList[0].Country?._id,
                        sales: res.data.prospectList[0].Sales?._id,
                        source: res.data.prospectList[0].Source?._id,
                        CustomerRefrence: res.data.prospectList[0].CustomerRefrence,
                        product: res.data.prospectList[0].Product?._id,
                        address: res.data.prospectList[0].Address,
                        requirements: res.data.prospectList[0].Requirements,
                        notes: res.data.prospectList[0].Notes
                    })
                    setdisplayuser(res.data.prospectList[0]);
                    getNext(val);
                    setdatanewtask(res.data.prospectasklist);
                    setdisplayprospect(true);
                    setprospect(val);
                    if (res.data.prospectList[0].is_readed == false) {
                        Get(`prospect/markAsRead/${val}`)
                            .then((res) => {
                                getpost({ active: true, appointment: "all" });
                            })
                            .catch((err) => {
                                showTost("SOMETHING WENT WRONG ON SERVER", "error");
                            });
                    }
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    };
    const getNext = async (val) => {
        Get(`prospect/getNext/${val}`)
            .then((res) => {
                setNext(res.data)
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const handleSubmit = async (val) => {
        if (prospect == 0)
            await Post(`prospect/add`, val)
                .then((res) => {
                    if (res.success == false) {
                        showTost(res.msg, "warning");
                    }
                    else {
                        clear();
                        setaddprospect(false);
                        Swal.fire({
                            title: "Your work has been saved",
                            icon: "success",
                            confirmButtonText: "OK",
                            confirmButtonColor: "#FD711A",
                        }).then(async ({ isConfirmed }) => {
                            if (isConfirmed) {
                                getpost({ active: true, appointment: "all" });
                            }
                        });
                    }
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        else {
            val.id = prospect;
            await Post(`prospect/edit`, val)
                .then((res) => {
                    if (res.success == false) {
                        showTost(res.msg, "warning");
                    }
                    else {
                        clear();
                        setaddprospect(false);
                        Swal.fire({
                            title: "Your work has been saved",
                            icon: "success",
                            confirmButtonText: "OK",
                            confirmButtonColor: "#FD711A",
                        }).then(async ({ isConfirmed }) => {
                            if (isConfirmed) {
                                prospectbyid(prospect);
                                getpost({ active: true, appointment: "all" });
                            }
                        });
                    }
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    };
    const move = async (pros_id, pros_stage, direction) => {
        let val = {};
        val.id = pros_id;
        val.stage = pros_stage;
        // Calculate the index of the current stage in the Status array
        const currentIndex = Status.findIndex(status => status._id === pros_stage);

        let newStage;
        if (direction === 'next') {
            newStage = Status[currentIndex + 1]?._id;
        } else if (direction === 'previous') {
            newStage = Status[currentIndex - 1]?._id;
        }
        if (newStage) {
            val.stage = newStage;
            await Post(`prospect/changeProspectStage`, val)
                .then((res) => {
                    getpost({ active: true, appointment: "all" });
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    };
    const remove = async (val) => {
        val.id = prospect;
        await Post(`prospect/remove`, val)
            .then((res) => {
                getpost({ active: true, appointment: "all" });
                setdeletes(false);
                setdisplayprospect(false);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };

    const movecustomer = async () => {
        await Get(`prospect/convertToCustomer/${prospect}`)
            .then((res) => {
                if (res.success == false) {
                    showTost(res.msg, "warning");
                }
                else {
                    setmovetocustomer(false);
                    setdisplayprospect(false);
                    getpost({ active: true, appointment: "all" });
                    showTost("New Customer Created", "success");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };

    // task functionality
    const [addtask, setaddtask] = useState(false);
    const [task, settask] = useState(0);
    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    const [initValueaddtask, setinitValueaddtask] = useState({
        id: "",
        Name: "",
        Priority: "",
        Assign: "",
        Status: "",
        Description: "",
        StartDate: null,
        EndDate: null,
        Reporter: [],
    });
    const clearaddtask = () => {
        setinitValueaddtask({
            Name: "",
            Priority: "",
            Assign: "",
            Status: "",
            Description: "",
            StartDate: null,
            EndDate: null,
            Reporter: [],
        });
    }

    const handleSubmitaddtask = async (val) => {
        val.ProspectId = prospect;
        await Post(`task/add`, val)
            .then((res) => {
                if (res.success == true) {
                    clearaddtask();
                    setaddtask(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            prospectbyid(prospect);
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const handleEditaddtask = async (val) => {
        val.id = task;
        await Post(`task/edit`, val)
            .then((res) => {
                if (res.success == true) {
                    clear();
                    setaddtask(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            prospectbyid(prospect);
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const genrateKeys = () => {
        setFormKey(Math.random() * 1000000);
    }
    const changestatus = (detvalues) => {
        let values = { ...detvalues };
        let name = Status.filter(f => f._id == values.Status)
        values.Assign = name[0]?.Assign;
        setinitValue(values);
        // genrateKeys();
    }
    const getbyid = async (val) => {
        if (val != 0) {
            Get(`task/getById/${val}`)
                .then((res) => {
                    setinitValueaddtask({
                        id: res.data._id,
                        Name: res.data.Name,
                        Priority: res.data.Priority,
                        Description: res.data.Description,
                        Assign: res.data.Assign,
                        Status: res.data.Status,
                        StartDate: moment(res.data.StartDate).format("YYYY-MM-DDTHH:mm"),
                        EndDate: moment(res.data.EndDate).format("YYYY-MM-DDTHH:mm"),
                        Reporter: res.data.Reporter,
                    });
                    setaddtask(true);
                    settask(val);
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    }
    const removetask = async (val) => {
        Swal.fire({
            title: "Are you sure want to delete ?",
            icon: "error",
            confirmButtonText: "Delete",
            confirmButtonColor: "#FD711A",
            showCancelButton: true,
            cancelButtonColor: "#0F3E6A",
        }).then(async ({ isConfirmed }) => {
            if (isConfirmed) {
                await Post(
                    `task/remove/${val}`
                )
                    .then(res => {
                        if (res.success == false) {
                            showTost(res.msg, "warning");
                        } else {
                            prospectbyid(prospect);
                        }
                    })
                    .catch((err) => {
                        showTost("SOMETHING WENT WRONG ON SERVER", "error");
                    });
            }
        });
    };
    return (
        <div className='content-wrapper'>
            <div className="row ">
                <div className="mb-2 mt-2 row col-12">
                    <div className="col-6">
                        <h2>Sales Prospects</h2>
                    </div>
                    <div className="col-6">
                        <div className="mt-2 float-right">
                            <span className='btn btn-sm btn-secondary mr-2' title="Filter Prospects" onClick={() => setfilter(true)}>
                                <i className="fa fa-filter" />
                            </span>
                            <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="download-table-xls-button btn btn-sm btn-success mr-2"
                                table="table-to-xls"
                                title="Export to Excel"
                                filename="Sales Prospects"
                                sheet="tablexls"
                                buttonText={<i className="fa fa-file-excel-o" />}
                            />
                            <span className='btn btn-sm btn-warning mr-2' title="Import Prospect From Excel" onClick={() => { inputRef.current.click(); }}>
                                <i className="fa fa-long-arrow-down" />
                            </span>
                            <input
                                style={{ display: 'none' }}
                                ref={inputRef}
                                type="file"
                                accept=".xls,.xlsx"
                                onChange={importprospect}
                            />
                            <span className='btn btn-sm btn-primary' onClick={() => { setaddprospect(true); clear(); setprospect(0); }}>
                                <i className="fa fa-plus mr-2" /> Add Prospect
                            </span>
                        </div>
                    </div>
                </div>
                <div className="mb-2 mt-2 ml-0 row col-12">
                    {Status.map((status, i) => (
                        <div key={i} className="col-lg-3 col-md-3 col-sm-12">
                            <div className="card">
                                <div className="card-header" style={{ backgroundColor: status.Color }}>
                                    <h5 className="text-white">{status.Name}
                                        <span>({data.filter((prospect) => prospect.Stage?._id === status._id).length})</span>
                                    </h5>
                                </div>
                                <div className="card-body p-3">
                                    <div className="custom-scrollbar pr-2" style={{ overflow: 'auto' }}>
                                        {data.filter((prospect, idx) => prospect.Stage?._id === status._id).map((prospect, index) => (
                                            <div key={index} className="text-white card py-3 px-2" style={{ background: status.Color }}>
                                                <div className='row'>
                                                    <div className='col-10' role="button" onClick={() => prospectbyid(prospect._id)}>
                                                        <div className={prospect.is_readed == true ? "f-14" : "font-weight-bold f-20"}>
                                                            <i className="fa fa-building-o mr-1 text-white"></i> <span>{prospect.Company}</span>
                                                        </div>
                                                        <div className={prospect.is_readed == true ? "f-14" : "font-weight-bold f-20"}>
                                                            <i className="fa fa-map-marker mr-1 text-white"></i> <span>{prospect.City?.Name}, {prospect.State?.Name}</span>
                                                        </div>
                                                    </div>
                                                    <div className='col-2 p-0'>
                                                        <div>
                                                            {status._id === Status[0]._id ? (
                                                                <i className='fa fa-long-arrow-right' role="button" onClick={() => move(prospect._id, prospect.Stage?._id, 'next')} />
                                                            ) : status._id === Status[Status.length - 1]._id ? (
                                                                <i className='fa fa-long-arrow-left' role="button" onClick={() => move(prospect._id, prospect.Stage?._id, 'previous')} />
                                                            ) : (
                                                                <>
                                                                    <div><i className='fa fa-long-arrow-right' role="button" onClick={() => move(prospect._id, prospect.Stage?._id, 'next')} /></div>
                                                                    <div><i className='fa fa-long-arrow-left' role="button" onClick={() => move(prospect._id, prospect.Stage?._id, 'previous')} /></div>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <table id="table-to-xls" style={{ display: 'none' }}>
                    <thead>
                        <tr>
                            <th>Company</th>
                            <th>GST No.</th>
                            <th>Contact</th>
                            <th>Sales</th>
                            <th>Mobile</th>
                            <th>Email</th>
                            <th>City</th>
                            <th>State</th>
                            <th>Country</th>
                            <th>Address</th>
                            <th>Stage</th>
                            <th>Source</th>
                            <th>Customer Refrence</th>
                            <th>Since</th>
                            <th>Next Action</th>
                            <th>Requirement</th>
                            <th>Product</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(item => (
                            <tr>
                                <td>{item.Company}</td>
                                <td>{item.GSTNo}</td>
                                <td>{item.FirstName} {item.LastName}</td>
                                <td>{item.Sales?.name}</td>
                                <td>{item.Mobile}</td>
                                <td>{item.Email}</td>
                                <td>{item.City?.Name}</td>
                                <td>{item.State?.Name}</td>
                                <td>{item.Country?.Name}</td>
                                <td>{item.Address}</td>
                                <td>{item.Stage?.Name}</td>
                                <td>{item.Source?.Name}</td>
                                <td>{item.CustomerRefrence?.Name}</td>
                                <td>{moment(item.createdAt).format("DD-MMM-YYYY")}</td>
                                <td>{item.NextTalk == null ? "" : moment(item.NextTalk?.date).format("DD-MMM-YYYY")}</td>
                                <td>{item.Requirements}</td>
                                <td>{item.Product?.Name}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <CModal alignment="center" size="lg" visible={addprospect}>
                    <div className="modal-header">
                        <h4 className="modal-title">{prospect == 0 ? "Add Prospect" : "Edit Prospect"}</h4>
                        <button type="button" className="close" onClick={() => { setaddprospect(false) }}>&times;</button>
                    </div>
                    <div className="modal-body">
                        <Formik
                            onSubmit={handleSubmit}
                            initialValues={initValue}
                            validationSchema={validation}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                touched,
                                errors,
                                setFieldValue,
                            }) => {
                                return (
                                    <Form
                                        onSubmit={handleSubmit}
                                        onChange={handleChange}>
                                        <div className="col-12 mb-2">
                                            <div className="row">
                                                <div className="col-8 mb-2">
                                                    <label><i className="fa fa-building mr-2" />Company</label>
                                                    <input type="text" placeholder="Company" defaultValue={values.company} name='company' className={!!touched.company && !!errors.company ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-4 mb-2">
                                                    <label>GST No.</label>
                                                    <input type="text" defaultValue={values.gstno} className={!!touched.gstno && !!errors.gstno ? "form-control is-invalid" : "form-control"} name='gstno' placeholder="GST No." />
                                                </div>
                                                <div className="col-3 mb-2">
                                                    <label><i className="fa fa-user text-danger mr-2" />Title</label>
                                                    <select defaultValue={values.title} className={!!touched.title && !!errors.title ? "form-select is-invalid" : "form-select"} name='title'>
                                                        <option value="">Select</option>
                                                        <option value="Mr.">Mr.</option>
                                                        <option value="Mrs.">Mrs.</option>
                                                        <option value="Ms.">Ms.</option>
                                                    </select>
                                                </div>
                                                <div className="col-4 mb-2">
                                                    <label>First Name</label>
                                                    <input type="text" defaultValue={values.firstname} className={!!touched.firstname && !!errors.firstname ? "form-control is-invalid" : "form-control"} name='firstname' placeholder="First Name" />
                                                </div>
                                                <div className="col-4 mb-2">
                                                    <label>Last Name</label>
                                                    <input type="text" defaultValue={values.lastname} className={!!touched.lastname && !!errors.lastname ? "form-control is-invalid" : "form-control"} name='lastname' placeholder="Last Name" />
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label><i className="fa fa-phone text-info mr-2" />Mobile No.</label>
                                                    <input type="text" placeholder="Mobile No." defaultValue={values.mobile} name='mobile' maxLength={10} className={!!touched.mobile && !!errors.mobile ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label><i className="fa fa-envelope text-primary mr-2" />Email</label>
                                                    <input type="email" placeholder="Email" defaultValue={values.email} name='email' className={!!touched.email && !!errors.email ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label><i className="fa fa-th-large text-success mr-2" />Product</label>
                                                    <div className='input-group'>
                                                        <select defaultValue={values.product} className={!!touched.product && !!errors.product ? "form-select is-invalid" : "form-select"} name='product'>
                                                            <option>Select Product</option>
                                                            {product
                                                                .map((x, i) => (
                                                                    <option key={i} value={x._id}>{x.Name}</option>
                                                                ))}
                                                        </select>
                                                        <span className="input-group-text">
                                                            <i className='fa fa-plus' onClick={() => setproductadd(true)} />
                                                            <ProductModal
                                                                setproductadd={setproductadd}
                                                                productadd={productadd}
                                                                getproductpost={getproductpost}
                                                                showTost={showTost}
                                                            />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label><i className="fa fa-wifi mr-2" />Source</label>
                                                    <div className='input-group'>
                                                        <select defaultValue={values.source} className={!!touched.source && !!errors.source ? "form-select is-invalid" : "form-select"} name='source'>
                                                            <option>Select Source</option>
                                                            {source
                                                                .map((x, i) => (
                                                                    <option key={i} value={x._id}>{x.Name}</option>
                                                                ))}
                                                        </select>
                                                        <span className="input-group-text">
                                                            <i className='fa fa-plus' onClick={() => setsourceModal(true)} />
                                                            <SourceModal
                                                                setsourceModal={setsourceModal}
                                                                sourceModal={sourceModal}
                                                                getsourcepost={getsourcepost}
                                                                showTost={showTost}
                                                            />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label>Customer Refrence</label>
                                                    <input type="text" placeholder="Customer Refrence" defaultValue={values.CustomerRefrence} name='CustomerRefrence' maxLength={10} className={!!touched.CustomerRefrence && !!errors.CustomerRefrence ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-6 mb-2">
                                                    <label><i className="fa fa-user text-secondary mr-2" />Sales</label>
                                                    <div className='input-group'>
                                                        <select defaultValue={values.sales} className={!!touched.sales && !!errors.sales ? "form-select is-invalid" : "form-select"} name='sales'>
                                                            <option>Select Sales</option>
                                                            {sales
                                                                .map((x, i) => (
                                                                    <option key={i} value={x._id}>{x.name}</option>
                                                                ))}
                                                        </select>
                                                        <span className="input-group-text">
                                                            <i className='fa fa-plus' onClick={() => setuseradd(true)} />
                                                            <UserModal
                                                                useradd={useradd}
                                                                setuseradd={setuseradd}
                                                                getuserpost={getuserpost}
                                                                showTost={showTost}
                                                            />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-4 mb-2">
                                                    <label><i className="fa fa-globe text-danger mr-2" />Country</label>
                                                    <select defaultValue={values.country} className={!!touched.country && !!errors.country ? "form-select is-invalid" : "form-select"} name='country'>
                                                        <option>Select Country</option>
                                                        {
                                                            Country.map((x, i) => (
                                                                <option key={i} value={x._id}>{x.Name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                                <div className="col-4 mb-2">
                                                    <label><i className="fa fa-map-marker text-warning mr-2" />State</label>
                                                    <select defaultValue={values.state} className={!!touched.state && !!errors.state ? "form-select is-invalid" : "form-select"} name='state'>
                                                        <option>Select State</option>
                                                        {State.filter((x) => x.Country?._id == values.country).map((x, i) => (
                                                            <option key={i} value={x._id}>{x.Name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-4 mb-2">
                                                    <label><i className="fa fa-fort-awesome text-success mr-2" />City</label>
                                                    <select name='city' defaultValue={values.city} className={!!touched.city && !!errors.city ? "form-control is-invalid" : "form-control"}>
                                                        <option>Select City</option>
                                                        {
                                                            City.filter((x) => x.State?._id == values.state).map((x, i) => (
                                                                <option key={i} value={x._id}>{x.Name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                                <div className="col-4 mb-2">
                                                    <label>Prospect Amount</label>
                                                    <input type="text" defaultValue={values.prospectAmount} className={!!touched.prospectAmount && !!errors.prospectAmount ? "form-control is-invalid" : "form-control"} name='prospectAmount' placeholder="Prospect Amount" />
                                                </div>
                                                <div className="col-4 mb-2">
                                                    <label>Order Target</label>
                                                    <input type="text" defaultValue={values.orderTarget} className={!!touched.orderTarget && !!errors.orderTarget ? "form-control is-invalid" : "form-control"} name='orderTarget' placeholder="Order Target" />
                                                </div>
                                                <div className="col-4 mb-2">
                                                    <label>Stage</label>
                                                    <select defaultValue={values.stage} className={!!touched.stage && !!errors.stage ? "form-select is-invalid" : "form-select"} name='stage'>
                                                        <option value=''>Select Stage</option>
                                                        {Status.map((x, i) => (<option key={i} value={x._id}>{x.Name}</option>))}
                                                    </select>
                                                </div>
                                                <div className="col-12 mb-2">
                                                    <label><i className="fa fa-address-card-o text-danger mr-2" />Address</label>
                                                    <textarea type="text" defaultValue={values.address} placeholder="Address" name='address' className={!!touched.address && !!errors.address ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-12 mb-2">
                                                    <label><i className="fa fa-comment text-info mr-2" />Requirements</label>
                                                    <textarea type="text" rows="3" defaultValue={values.requirements} placeholder="Requirements" name='requirements' className={!!touched.requirements && !!errors.requirements ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className="col-12 mb-2">
                                                    <label><i className="fa fa-sticky-note text-primary mr-2" />Notes</label>
                                                    <textarea type="text" rows="3" defaultValue={values.notes} placeholder="Notes" name='notes' className={!!touched.notes && !!errors.notes ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 mt-2'>
                                            <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                        </div>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </div>
                </CModal>
                <CModal alignment="center" size='lg' visible={displayprospect}>
                    <div className="modal-header">
                        <h4 className="modal-title">{displayuser.Company}<div onClick={() => setaddprospect(true)} className='btn btn-sm btn-success ml-3 rounded-circle'><i className='fa fa-pencil' /></div></h4>
                        <button type="button" className="close" onClick={() => setdisplayprospect(false)}>&times;</button>
                    </div>
                    <div className="modal-body">
                        <div className="col-12">
                            <div className="row">
                                <div className='col-6'>
                                    <div>
                                        <i className="fa fa-user mr-2" /> {displayuser.Title} {displayuser.FirstName} {displayuser.LastName}
                                    </div>
                                    <div>
                                        <i className="fa fa-envelope mr-2" />{displayuser.Email}
                                    </div>
                                    <div>
                                        <i className="fa fa-phone mr-2" /> {displayuser.Mobile}
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div>
                                        <i className="fa fa-calendar mr-2" /> {moment(displayuser.createdAt).format("DD-MMM")}
                                    </div>
                                    <div>
                                        <i className="fa fa-wifi mr-2" />{displayuser.Source?.Name}
                                    </div>
                                    <div>
                                        <i className="fa fa-user mr-2 ml-1" /> {displayuser.Sales?.name}
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <span className='btn btn-info btn-sm mr-1'>
                                        Stage : {displayuser.Stage?.Name}
                                    </span>
                                    <span className='btn btn-danger btn-sm mr-1' onClick={() => setdeletes(true)}>
                                        <i className="fa fa-trash mr-2" />
                                        Delete Prospect
                                    </span>
                                    {displayuser.is_customer == false ?
                                        <span className='btn btn-success btn-sm mr-1' onClick={() => setmovetocustomer(true)}>
                                            <i className="fa fa-money mr-2" /> Convert to Customer
                                        </span> : ""}
                                    <span className='btn btn-warning btn-sm mr-1' onClick={() => contactbyid(prospect)}>
                                        <i className="fa fa-phone mr-2" /> Other Contacts
                                    </span>
                                    <span className='btn btn-warning btn-sm mr-1' onClick={() => { setaddtask(true); settask(0); }}>
                                        <i className="fa fa-plus mr-2" /> Add Task
                                    </span>
                                </div>
                                <div className='col-5'>
                                    <div className="border rounded border-danger mt-2 p-2">
                                        <div>
                                            <div className="col-12 text-danger">
                                                <span><i className="fa fa-comment" /> Needs</span>
                                            </div>
                                            <div className="col-12">
                                                <span>{displayuser.Requirements}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="col-12 text-danger">
                                                <span><i className="fa fa-sticky-note" /> Notes</span>
                                            </div>
                                            <div className="col-12">
                                                <span>{displayuser.Notes}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="border rounded border-success p-2 mt-2" >
                                        <div>
                                            <div className="col-12 text-success" >
                                                <span><i className="fa fa-calendar" /> Next Action</span>
                                            </div>
                                            <div className="col-12">
                                                {displayuser.NextTalk !== null ?
                                                    <span>{displayuser.NextTalk?.note}<br />{moment(displayuser.NextTalk?.date).format("DD-MMM-YYYY")}</span>
                                                    : ""}</div>
                                            <div className="col-12">
                                                <span className='btn btn-sm btn-success' onClick={() => { clearnext(); setnextaction(true) }}>Enter Next Action</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-7'>
                                    <div className="border rounded border-warning mt-2 p-2">
                                        <div className="col-12 text-info">
                                            <span>Interactions</span>
                                        </div>
                                        <div style={{ overflow: "auto", height: '250px' }}>
                                            {Next.map((x, i) => (
                                                <div className='row card p-3'><div className='text-secondary' style={{ fontSize: '12px' }}>{x.user?.name}<div className='float-right'>{moment(x.date).format("DD-MMM")} <button className='btn btn-sm btn-success' onClick={() => editnext(x)}><i className='fa fa-pencil' /></button></div></div>
                                                    <div>{x.note}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className="border rounded border-info mt-2 p-2">
                                        <div className="text-info">
                                            <span>Tasks</span>
                                        </div>
                                        {datanewtask?.map((x) => (
                                            <div className='card p-2' key={x._id}>
                                                <div className='row'>
                                                    <div className='col-sm-10'>
                                                        <div>Task Name: <b className='ml-2'>{x.Name}</b></div>
                                                        <div>Assign: <b className='ml-2'>{x.Assign?.name}</b></div>
                                                        <div>Status: <b className='ml-2'>{x.Status?.Name}</b></div>
                                                        <div>End Date: <b className='ml-2'>{moment(x.EndDate).format('lll')}</b></div>
                                                    </div>
                                                    <div className='col-sm-2 text-end'>
                                                        <i className='btn btn-sm btn-success fa fa-pencil mr-2' onClick={() => getbyid(x._id)} />
                                                        <i className='btn btn-danger btn-sm fa fa-trash' onClick={() => removetask(x._id)} />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </CModal>
                <CModal alignment="center" visible={contacts}>
                    <div className="modal-header">
                        <h4 className="modal-title">Contacts - {displayuser.Company}</h4>
                        <button type="button" className="close" onClick={() => setcontacts(false)}>&times;</button>
                    </div>
                    <div className="modal-body">
                        <h4 className='text-success'>Primary Contact</h4>
                        <div className='row'>
                            <div className='col-4'>{displayuser.Title} {displayuser.FirstName} {displayuser.LastName}</div>
                            <div className='col-3'>{displayuser.Mobile}</div>
                            <div className='col-3'>{displayuser.Email}</div>
                        </div>
                        <h4 className='text-info'>Contacts</h4>
                        {contactsdetails.map((x, i) => {
                            return (
                                <div className='row'>
                                    <div className='col-4'>{x.Name}</div>
                                    <div className='col-3'>{x.Mobile}</div>
                                    <div className='col-3'>{x.Email}</div>
                                </div>
                            );
                        })}
                        <div className='row mt-3'>
                            <div className='text-warning col-4'>Other Contacts</div>
                            <div className='btn btn-sm btn-warning col-2' onClick={() => $('.contactadd').show()}><i className="fa fa-plus mr-2" />Add</div>
                        </div>

                        <Formik
                            onSubmit={savecontact}
                            initialValues={initValuecontact}
                            validationSchema={validationcontact}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                touched,
                                errors,
                                setFieldValue,
                            }) => {
                                return (
                                    <Form
                                        onSubmit={handleSubmit}
                                        onChange={handleChange}>
                                        <div className='row mt-3 contactadd' style={{ display: "none" }}>
                                            <div className='col-4'><input type='text' placeholder='Contact Name' name='name' className={!!touched.name && !!errors.name ? "form-control is-invalid" : "form-control"} /></div>
                                            <div className='col-3'><input type="text" placeholder="Mobile No." name='mobile' maxLength={10} className={!!touched.mobile && !!errors.mobile ? "form-control is-invalid" : "form-control"} /></div>
                                            <div className='col-3'><input type='email' placeholder='Email' name='email' className={!!touched.email && !!errors.email ? "form-control is-invalid" : "form-control"} /></div>
                                            <div className='col-1'><button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /></button></div>
                                        </div>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </div>
                </CModal>
                <CModal alignment="center" visible={nextaction}>
                    <div className="modal-header">
                        <h4 className="modal-title">Enter Next Action for {displayuser.Company}</h4>
                        <button type="button" className="close" onClick={() => setnextaction(false)}>&times;</button>
                    </div>
                    <div className="modal-body">
                        <Formik
                            onSubmit={savenext}
                            initialValues={initValuenext}
                            validationSchema={validationnext}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                touched,
                                errors,
                                setFieldValue,
                            }) => {
                                return (
                                    <Form
                                        onSubmit={handleSubmit}
                                        onChange={handleChange}>
                                        <div className='row'>
                                            <div className='col-6'><input type='date' value={values.date} name='date' className={!!touched.date && !!errors.date ? "form-control is-invalid" : "form-control"} /></div>
                                            <div className='col-12 mt-2'><textarea rows="3" type='text' value={values.note} name='note' className={!!touched.note && !!errors.note ? "form-control is-invalid" : "form-control"} placeholder='Note' /></div>
                                        </div>
                                        <div className='mt-2'>
                                            <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                        </div>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </div>
                </CModal>
                <CModal alignment="center" visible={deletes}>
                    <div className="modal-header">
                        <h4 className="modal-title">Delete Prospect</h4>
                        <button type="button" className="close" onClick={() => setdeletes(false)}>&times;</button>
                    </div>
                    <div className="modal-body">
                        <Formik
                            onSubmit={remove}
                            initialValues={initValueremove}
                            validationSchema={validationremove}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                touched,
                                errors,
                                setFieldValue,
                            }) => {
                                return (
                                    <Form
                                        onSubmit={handleSubmit}
                                        onChange={handleChange}>
                                        <h6 className='mb-3'>{displayuser.Company}</h6>
                                        <input type='text' name='reason' className={!!touched.reason && !!errors.reason ? "form-control is-invalid" : "form-control"} placeholder='Reason' />
                                        <button type="submit" className='btn btn-success btn-sm font-weight-bold mt-3'><i className='fa fa-check' /> Save</button>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </div>
                </CModal>
                <CModal alignment="center" size="sm" visible={movetocustomer}>
                    <div className="modal-header">
                        <h4 className="modal-title">Allegiance Water Management</h4>
                        <button type="button" className="close" onClick={() => setmovetocustomer(false)}>&times;</button>
                    </div>
                    <div className="modal-body">
                        <label>Are you sure you wish to convert this prospect to customer?</label>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className='btn btn-sm font-weight-bold' onClick={() => movecustomer()}> YES</button>
                        <button type="button" className='btn btn-sm font-weight-bold' onClick={() => setmovetocustomer(false)}> NO</button>
                    </div>
                </CModal>
                <CModal alignment="center" size="sm" visible={filter}>
                    <div className="modal-header">
                        <h4 className="modal-title">Filter Prospects</h4>
                        <button type="button" className="close" onClick={() => setfilter(false)}>&times;</button>
                    </div>
                    <div className="modal-body">
                        <Formik
                            onSubmit={getpost}
                            initialValues={{
                                active: true,
                                appointment: "all",
                                sales: "",
                                source: "",
                                leadSince: "",
                                followup: "",
                                unread: false
                            }}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                touched,
                                errors,
                                setFieldValue,
                            }) => {
                                return (
                                    <Form
                                        onSubmit={handleSubmit}
                                        onChange={handleChange}>
                                        <div>
                                            <span>
                                                <i className='fa fa-user text-danger' /> Assigned to
                                                <select defaultValue={values.sales} className={!!touched.sales && !!errors.sales ? "form-select is-invalid" : "form-select"} name='sales'>
                                                    <option value="">Select Sales</option>
                                                    {sales
                                                        .map((x, i) => (
                                                            <option key={i} value={x._id}>{x.name}</option>
                                                        ))}
                                                </select>
                                            </span>
                                            <span>
                                                <i className='fa fa-wifi text-info' /> Source
                                                <select defaultValue={values.source} className={!!touched.source && !!errors.source ? "form-select is-invalid" : "form-select"} name='source'>
                                                    <option value="">Select Source</option>
                                                    {source
                                                        .map((x, i) => (
                                                            <option key={i} value={x._id}>{x.Name}</option>
                                                        ))}
                                                </select>
                                            </span>
                                            <span>
                                                <i className='fa fa-calendar-plus-o text-success' /> Leads added in
                                                <input type='text' name='leadSince' className={!!touched.leadSince && !!errors.leadSince ? "form-control is-invalid" : "form-control"} placeholder='' />
                                            </span>
                                            <span>
                                                <i className='fa fa-calendar-check-o text-secondary' /> Follow-up on
                                                <input type='date' name='followup' className={!!touched.followup && !!errors.followup ? "form-control is-invalid" : "form-control"} placeholder='Select Date' />
                                            </span>
                                            <span>
                                                <input type='checkbox' name='unread' value={true} /> View only Unread
                                            </span>
                                        </div>
                                        <button type="submit" className='btn btn-success btn-sm font-weight-bold mt-3'><i className='fa fa-check' /> Apply</button>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </div>
                </CModal>

                <CModal alignment="center" size="lg" visible={addtask}>
                    <div className="modal-header">
                        <h4 className="modal-title">{task == 0 ? "Add Task" : "Update Task"}</h4>
                        <button type="button" className="close" onClick={() => { clearaddtask(); setaddtask(false) }}>&times;</button>
                    </div>
                    <div className="modal-body">
                        <Formik
                            onSubmit={task == 0 ? handleSubmitaddtask : handleEditaddtask}
                            initialValues={initValueaddtask}
                            validationSchema={validationaddtask}
                            key={formkey}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                touched,
                                errors,
                                setFieldValue,
                            }) => {
                                return (
                                    <Form
                                        onSubmit={handleSubmit}
                                        onChange={handleChange}>
                                        <div className="col-12 mb-2">
                                            <div className="row">
                                                <div className='col-12 mt-2'>
                                                    <label>Name</label>
                                                    <input type='text' name="Name" defaultValue={values.Name} placeholder='Enter Task' className={!!touched.Name && !!errors.Name ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className='col-6 mt-2'>
                                                    <label>Status</label>
                                                    <select name="Status" defaultValue={values.Status} onChange={handleChange} onBlur={() => { changestatus(values) }} className={!!touched.Status && !!errors.Status ? "form-select is-invalid" : "form-select"}>
                                                        <option value="">Select Status</option>
                                                        {Status
                                                            .map((x, i) => (
                                                                <option key={i} value={x._id}>{x.Name}</option>
                                                            ))}
                                                    </select>
                                                </div>
                                                <div className='col-6 mt-2'>
                                                    <label>Assign to :</label>
                                                    <select name="Assign" defaultValue={values.Assign} className={!!touched.Assign && !!errors.Assign ? "form-select is-invalid" : "form-select"}>
                                                        <option value="">Select Assign</option>
                                                        {sales
                                                            .map((x, i) => (
                                                                <option key={i} value={x._id}>{x.name}</option>
                                                            ))}
                                                    </select>
                                                </div>
                                                <div className='col-6 mt-2'>
                                                    <label>Priority</label>
                                                    <select name="Priority" defaultValue={values.Priority} className={!!touched.Priority && !!errors.Priority ? "form-select is-invalid" : "form-select"}>
                                                        <option value="">Select Priority</option>
                                                        <option>High</option>
                                                        <option>Normal</option>
                                                        <option>Low</option>
                                                    </select>
                                                </div>
                                                <div className='col-6 mt-2'>
                                                    <label>Start Date</label>
                                                    <input type="datetime-local" placeholder="Date" defaultValue={values.StartDate} name='StartDate' className={!!touched.StartDate && !!errors.StartDate ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className='col-6 mt-2'>
                                                    <label>End Date</label>
                                                    <input type="datetime-local" placeholder="Date" defaultValue={values.EndDate} name='EndDate' className={!!touched.EndDate && !!errors.EndDate ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className='col-6 mt-2'>
                                                    <label>Reporter</label>
                                                    <Select
                                                        isMulti
                                                        name="Reporter"
                                                        value={
                                                            sales.filter(
                                                                (x) => {
                                                                    if (values.Reporter && values.Reporter.length) {
                                                                        return values.Reporter.indexOf(x._id) != -1;
                                                                    } else {
                                                                        return values.Reporter == x._id;
                                                                    }
                                                                }
                                                            )
                                                                .map((x) => ({
                                                                    value: x._id,
                                                                    label: x.name,
                                                                }))}
                                                        onChange={(e) =>
                                                            setFieldValue(
                                                                "Reporter",
                                                                e.map((x) => x.value)
                                                            )
                                                        }
                                                        options={sales.map((x) => ({
                                                            value: x._id,
                                                            label: x.name,
                                                        }))}
                                                        className={touched.Reporter && errors.Reporter ? "is-invalid" : ""}
                                                    />
                                                </div>
                                                <div className='col-12 mt-2'>
                                                    <label>Description</label>
                                                    <textarea type='text' rows="3" defaultValue={values.Description} name="Description" placeholder='Enter Description' className={!!touched.Description && !!errors.Description ? "form-control is-invalid" : "form-control"} />
                                                </div>
                                                <div className='col-12 mt-2'>
                                                    <button type="submit" className='btn font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </CModal>

            </div>
        </div>
    );
}

export default Prospects;