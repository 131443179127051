import React from "react";
import { useEffect, useState } from "react";
import { Post, Get } from '../../Layout/ajax';
import { immediateToast } from "izitoast-react";

const FooterLayout = () => {
  const [Manual, setManual] = useState('');
  const currentYear = new Date().getFullYear();

  const showTost = (msg, theme) => {
    immediateToast(theme, {
      message: msg,
      timeout: 3000
    });
  }

  useEffect(() => {
    Get(`master/getApplicationSetting`)
    .then((res) => {
        setManual(res.data.CompanyTitle);
    })
    .catch((err) => {
        showTost("SOMETHING WENT WRONG ON SERVER", "error");
    });
  
  }, []);

  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 footer-copyright">
            <p className="mb-0">Copyright {currentYear} © {Manual} All rights reserved </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterLayout;
