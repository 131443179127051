import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { Post, Get } from '../../Layout/ajax';
import { CModal } from '@coreui/react';
import { immediateToast } from "izitoast-react";
import * as Yup from "yup";
import Swal from "sweetalert2";

const validation = Yup.object().shape({
    Name: Yup.string().required(),
    Address: Yup.string().required(),
    Password: Yup.string().required(),
    Server: Yup.string().required(),
    Port: Yup.string().required(),
});
const MailAddress = () => {
    const [initValue, setinitValue] = useState({
        Name: "",
        Address: "",
        Password: "",
        Server: "",
        Port: "",
    });
    const [data, setData] = useState([]);
    const [mailaddressadd, setmailaddressadd] = useState(false);
    const [mailaddress, setmailaddress] = useState(0);

    useEffect(() => {
        getpost(true);
    }, []);
    const showTost = (msg, theme) => {
        immediateToast(theme, {
            message: msg,
            timeout: 3000
        });
    }
    const getpost = async (val) => {
        await Post(`master/getMailAddress`, { active: val })
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const handleSubmit = async (val) => {
        await Post(`master/addMailAddress`, val)
            .then((res) => {
                if (res.success == true) {
                    clear();
                    setmailaddressadd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getpost(true);
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const handleEdit = async (val) => {
        val.id = mailaddress;
        await Post(`master/editMailAddress`, val)
            .then((res) => {
                if (res.success == true) {
                    clear();
                    setmailaddressadd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getpost(true);
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const clear = () => {
        setinitValue({
            Name: "",
            Address: "",
            Password: "",
            Server: "",
            Port: "",
        });
    }
    const get = async (val) => {
        if (val != 0) {
            Get(`master/MailAddress/${val}`)
                .then((res) => {
                    setinitValue({
                        Name: res.data.Name,
                        Address: res.data.Address,
                        Password: res.data.Password,
                        Server: res.data.Server,
                        Port: res.data.Port,
                    });
                    setmailaddressadd(true);
                    setmailaddress(val);
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    }
    const changeStatus = async (val, status) => {
        await Post(`master/changeMailAddressStatus`, { id: val, active: status })
            .then((res) => {
                if (res.success == true) {
                    if (status == true) {
                        getpost(false);
                        showTost("Your data has been successfully activated", "success");
                    }
                    else {
                        getpost(true);
                        showTost("Your data has been successfully deactivated", "success");
                    }
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const DefaultMailAddress = async (addid) => {
        var valnew = {}
        valnew.addressId = addid;
        valnew.default = true;
        await Post(`master/setDefaultMailAddress`, valnew)
            .then((res) => {
                if (res.success == false) {
                    showTost(res.msg, "warning");
                }
                else {
                    getpost(true);
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const columns = [
        {
            name: "Mailer Name",
            selector: (row) => row.Name,
        },
        {
            name: "Email Address",
            selector: (row) => row.Address,
        },
        {
            name: "Outgoing Server",
            selector: (row) => row.Server,
        },
        {
            name: "Outgoing Port",
            selector: (row) => row.Port,
        },
        {
            name: "Default",
            selector: (row) => <input type='checkbox' checked={row.is_default == true ? "checked" : ""} onClick={() => DefaultMailAddress(row._id)} />,
        },
        {
            name: "Status",
            selector: (row) => <input type='checkbox' value={row.is_active} checked={row.is_active == true ? "checked" : ""} onClick={() => { row.is_active == true ? changeStatus(row._id, false) : changeStatus(row._id, true) }} />,
        },
        {
            name: "",
            selector: (row) => row.is_active == true ? <i className='btn btn-sm btn-success fa fa-pencil' onClick={() => { get(row._id); }} /> : "",
        },
    ];

    return (
        <div className='content-wrapper'>
            <div className="row ">
                <div className="mb-2 mt-2 row col-12">
                    <div className="col-10">
                        <h2>Mail Address</h2>
                    </div>
                    <div className="col-2 mt-2">
                        <span className='btn btn-sm btn-warning float-right' onClick={() => { setmailaddressadd(true); setmailaddress(0); }}>
                            <i className="fa fa-plus mr-2" />
                            Add Mail Address
                        </span>
                    </div>
                    <div className="col-12">
                        <div className="custom-control custom-switch float-right">
                            <input type="checkbox" className="custom-control-input" defaultChecked id="customSwitch1" data-check_true={true} data-check_false={false} onChange={(event) => {
                                let ele = event.target;
                                let selectedId = ele.checked ? event.target.getAttribute("data-check_true") : event.target.getAttribute("data-check_false");
                                getpost(selectedId)
                            }} />
                            <label className="custom-control-label" for="customSwitch1"> Active/Inactive </label>
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-3">
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                    />
                </div>
            </div>
            <CModal alignment="center" visible={mailaddressadd}>
                <div className="modal-header">
                    <h4 className="modal-title">{mailaddress == 0 ? "Add Mail Address" : "Update Mail Address"}</h4>
                    <button type="button" className="close" onClick={() => { clear(); setmailaddressadd(false) }}>&times;</button>
                </div>
                <div className="modal-body">
                    <Formik
                        onSubmit={mailaddress == 0 ? handleSubmit : handleEdit}
                        initialValues={initValue}
                        validationSchema={validation}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div className='mt-2'>
                                        <label>Mailer Name</label>
                                        <input type='text' name="Name" defaultValue={values.Name} placeholder='Enter Mailer Name' className={!!touched.Name && !!errors.Name ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='mt-2'>
                                        <label>Email Address</label>
                                        <input type='text' name="Address" defaultValue={values.Address} placeholder='Enter Email Address' className={!!touched.Address && !!errors.Address ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='mt-2'>
                                        <label>Email Password</label>
                                        <input type='password' name="Password" defaultValue={values.Password} placeholder='Email Password' className={!!touched.Password && !!errors.Password ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='mt-2'>
                                        <label>Outgoing Server</label>
                                        <input type='text' name="Server" defaultValue={values.Server} placeholder='Enter Outgoing Server' className={!!touched.Server && !!errors.Server ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='mt-2'>
                                        <label>Outgoing Port</label>
                                        <input type='text' name="Port" defaultValue={values.Port} placeholder='Enter Outgoing Port' className={!!touched.Port && !!errors.Port ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='mt-2'>
                                        <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </CModal>
        </div >
    );
}

export default MailAddress;