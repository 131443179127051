import { Link, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { ActiveNavLinkUrl } from '../../Service';
import { sidebarTypes } from './SideBarTypes';
import { LI, UL } from '../../AbstractElements';
import { RoleRight } from '../../redux-toolkit/store';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from "react-redux";
import ConfigDB from '../../config/ThemeConfig';
const SidebarSubMenu = ({ menu, className, setIsOpen, isOpen, level }) => {
  const dispatch = useDispatch()
  const $ = window.$;
  const { Modules } = useSelector((state) => state.RoleRight);
  const sideBarType = ConfigDB.data.settings.sidebar.type
  const { pathname } = useLocation();
  const { t } = useTranslation();

  function shouldSetActive({ item }) {
    var returnValue = false;
    if (item?.url === pathname) {
      returnValue = true;
    }
    if (!returnValue && item?.menu) {
      item?.menu.every((subItem) => {
        returnValue = shouldSetActive({ item: subItem });
        return !returnValue;
      });
    }
    return returnValue;
  }
  useEffect(() => {
    menu.forEach((item) => {
      let gotValue = shouldSetActive({ item });
      if (gotValue) {
        let temp = isOpen;
        temp[level] = item.title;
        setIsOpen(temp);
      }
    });
  }, []);

  const hasRights = (modulename) => {
    let haveRight = false;
    let moduleright = Modules.find(f => f.modulename == modulename);
    if (moduleright) {
      haveRight = moduleright.read;
    }
    return haveRight;
  }

  return (
    <>
      {menu.map((item, i) => (
        hasRights(item.title) &&
          <LI key={i} className={`${className ? '' : 'dropdown'} ${(item.menu ? item.menu.map((innerItem) => ActiveNavLinkUrl(innerItem.url)).includes(true) : ActiveNavLinkUrl(item.url)) || isOpen[level] === item.title ? 'active' : ''} `}>
          <Link
              className={`${className ? '' : 'nav-link menu-title'}  ${(item.menu ? item.menu.map((innerItem) => ActiveNavLinkUrl(innerItem.url)).includes(true) : ActiveNavLinkUrl(item.url)) || isOpen[level] === item.title ? 'active' : ''}`}
              to={item.url ? item.url : '#javascript'}
              onClick={() => {
                const temp = isOpen;
                temp[level] = item.title !== temp[level] && item.title;
                setIsOpen(temp);
                if (window.outerWidth < 768) {
                  if (item.url) {
                    dispatch({ type: "setToggleIcon", payload: true })
                  }
                }
                $("html, body").animate({ scrollTop: 0 }, 600);
              }}>
              {level === 0 && item.icon ? item.icon : ""}
              <span >{t(item.title)}</span>
              {item.menu && (
                sideBarType === "horizontal-wrapper" && level > 0 ? <span className="sub-arrow"><i className="fa fa-chevron-right" /></span> :
                  <span className='according-menu'>
                    <i className="fa fa-angle-double-right"></i>
                  </span>
              )}
            </Link>
          {item.menu &&
            <UL className={sideBarType === "horizontal-wrapper" && level > 0 ? "nav-sub-childmenu submenu-content" : "nav-submenu menu-content"}>
              <SidebarSubMenu menu={item.menu} isOpen={isOpen} setIsOpen={setIsOpen} level={level + 1} className='sidebar-submenu' />
            </UL>
          }
        </LI>
      ))}
    </>
  );
};
export default SidebarSubMenu;
