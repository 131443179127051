import { ChevronRight, LogIn, Key, User, Settings } from "react-feather";
import { Image, LI, UL } from "../../AbstractElements";
import React, { useEffect, useState } from 'react';
import { ProfilesMessage } from "../../Data/Layout/Header/Profiles";
import { dynamicImage } from "../../Service";
import { Admin, ElanaSaint, Logout, Profile } from "../../Constant";
import { useNavigate } from 'react-router';
import { Post, Get } from '../../Layout/ajax';
import { Link } from "react-router-dom";
import { Formik, Field, ErrorMessage } from "formik";
import { Form, Button } from "react-bootstrap";
import { immediateToast } from "izitoast-react";
import { CModal } from '@coreui/react';
import * as Yup from "yup";

const validation = Yup.object().shape({
  currentPassword: Yup.string().required(),
  newPassword: Yup.string().required(),
  confirmnewPassword: Yup.string().required()
    .oneOf([Yup.ref('newPassword')], 'Passwords must and should match'),
});
const UserProfile = () => {
  const navigate = useNavigate()

  const [changepasswordmodal, setchangepasswordmodal] = useState(false);
  const [logoutmodal, setlogoutmodal] = useState(false);
  const handleLogOut = () => {
    window.localStorage.removeItem("Id");
    window.localStorage.removeItem("Token");
    window.localStorage.removeItem("Email");
    window.localStorage.removeItem("Role");
    window.localStorage.removeItem("RoleName");
    window.localStorage.removeItem("Name");
    window.location.reload();
    window.location.replace("/Login")
  }
  const showTost = (msg, theme) => {
    immediateToast(theme, {
      message: msg,
      timeout: 3000
    });
  }
  const [initValue, setinitValue] = useState({
    currentPassword: "",
    newPassword: "",
    confirmnewPassword: ""
  });
  const handleSubmit = async (val) => {
    val.id = JSON.parse(window.localStorage.getItem("Id"));
    await Post(`users/changePassword`, val)
      .then((res) => {
        window.localStorage.removeItem("Token");
        window.localStorage.removeItem("Name");
        window.localStorage.removeItem("Email");
        window.localStorage.removeItem("Id");
        window.localStorage.removeItem("Role");
        window.location.reload();
        window.location.replace('/Login');
      })
      .catch((err) => {
        showTost("Wrong Current Password!", "warning");
      });
  };
  return (
    <LI className="onhover-dropdown px-0">
      <span className="d-flex user-header">
        {/* <Image
          className="me-2 rounded-circle img-35"
          src={dynamicImage("dashboard/user.png")}
          alt="userdp"
        /> */}
        <span style={{ padding: "6px 10px", border: "1px solid #dfe5f5", borderRadius: "4px" }}>
          <Settings />
        </span>
      </span>
      <UL className="chat-dropdown profile-dropdown  onhover-show-div simple-list">
        <LI>
          <Link>
            <span className="f-12 f-w-600"> <User /> {JSON.parse(window.localStorage.getItem("Name"))}</span>
            <span className="d-block">{JSON.parse(window.localStorage.getItem("Email"))}</span>
          </Link>
        </LI>
        <LI className="f-w-600">Home</LI>
        <LI className="f-12" onClick={() => setchangepasswordmodal(true)}>
          <Key />Change Password
        </LI>
        <LI onClick={() => setlogoutmodal(true)}>
          <LogIn />
          {Logout}
        </LI>
      </UL>
      <CModal alignment="center" size="sm" visible={changepasswordmodal}>
        <div className="modal-header">
          <h4 className="modal-title">Change Password</h4>
          <button type="button" className="close" onClick={() => setchangepasswordmodal(false)}>&times;</button>
        </div>
        <div className="modal-body">
          <Formik
            onSubmit={handleSubmit}
            initialValues={initValue}
            validationSchema={validation}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              touched,
              errors,
              setFieldValue,
            }) => {
              return (
                <Form
                  onSubmit={handleSubmit}
                  onChange={handleChange}>
                  <div className="row">
                    <div className='col-12'>
                      <label>Enter Old Password</label>
                      <input type="password" name='currentPassword' className={!!touched.currentPassword && !!errors.currentPassword ? "form-control is-invalid" : "form-control"} />
                    </div>
                    <div className='col-12'>
                      <label>Enter New Password</label>
                      <input type="password" name='newPassword' className={!!touched.newPassword && !!errors.newPassword ? "form-control is-invalid" : "form-control"} />
                    </div>
                    <div className='col-12'>
                      <label>Confirm Password</label>
                      <input type="password" name='confirmnewPassword' className={!!touched.confirmnewPassword && !!errors.confirmnewPassword ? "form-control is-invalid" : "form-control"} />
                    </div>
                    <div className='col-12 mt-3'>
                      <button type="submit" className='btn btn-success'><i className='fa fa-check' /> Change</button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </CModal>
      <CModal alignment="center" size="sm" visible={logoutmodal}>
        <div className="modal-header">
          <h4 className="modal-title">Allegiance Water Management</h4>
          <button type="button" className="close" onClick={() => setlogoutmodal(false)}>&times;</button>
        </div>
        <div className="modal-body">
          <label>Are you sure you wish to logout?</label>
        </div>
        <div className="modal-footer">
          <button type="button" className='btn font-weight-bold' onClick={() => handleLogOut()}> YES</button>
          <button type="button" className='btn font-weight-bold' onClick={() => setlogoutmodal(false)}> NO</button>
        </div>
      </CModal>
    </LI>
  );
};

export default UserProfile;
