import React, { Fragment, useEffect, useState } from "react";
import { Bell } from "react-feather";
import { H6, LI, P, UL } from "../../AbstractElements";
import { Link } from "react-router-dom";
import { immediateToast } from "izitoast-react";
import { Post, Get } from '../../Layout/ajax';
import moment from "moment";

const BellCompo = () => {
  const [notification, setnotification] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const showTost = (msg, theme) => {
    immediateToast(theme, {
      message: msg,
      timeout: 3000
    });
  }
  const getPost = async () => {
    let skipa = perPage * (pageNo - 1);
    Post(`notification/getNotification`, { "userId": JSON.parse(window.localStorage.getItem('Id')) })
      .then((res) => {
        setnotification(res.data);
      })
      .catch((err) => {
        showTost("SOMETHING WENT WRONG ON SERVER", "error");
      });
  }
  useEffect(() => {
    getPost();
  }, [])
  return (
    <Fragment>
      <LI className="onhover-dropdown position-relative">
        <Bell />
        {notification.Count == 0 ? " " : (
          <div className="notificationlen">
          {notification.Count}
        </div>
        ) }
        
        <UL className="chat-dropdown notification-dropdown onhover-show-div simple-list">
          <LI>
            <H6 className="f-w-600">Notifications</H6>
          </LI>
          {notification.data?.map((x, i) => (
            <LI key={i}>
            <div style={{ borderBottom: '1px solid lightgrey' }}>
              <div>
                <span><i className="fa fa-calendar-o mr-2 text-primary"></i></span>
                <span>{moment(x.date).format("DD-MMM-YYYY")}</span>
              </div>
              <div>
                {x.description}
              </div>
            </div>
          </LI>
          ))}
          <LI className="bg-light txt-dark">
            <Link to={'/Notification'}>All notification</Link>
          </LI>
        </UL>
      </LI>
    </Fragment>
  );
};

export default BellCompo;
