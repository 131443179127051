import { Fragment } from "react";
import { ListGroup } from "reactstrap";

const UL = (props) => {
  return (
    <Fragment>
      <ListGroup {...props}>{props.children}</ListGroup>
    </Fragment>
  );
};

export default UL;
