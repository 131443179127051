import React, { useEffect, useState } from 'react';

const Support = () => {
    return (
        <div className='content-wrapper'>
            <div className="row">
                <div className="col-6">
                    <h2>Accounts</h2>
                </div>
                <div className="col-2.5 mr-2 mt-1">
                    <span className="form-control">01-Apr-23 to 31-Mar-24 </span>
                </div>
                <div className="col-1.5 mt-2">
                    <span className='btn btn-sm btn-danger mr-2' >
                        <i className="fa fa-plus" /> Enter Voucher
                    </span>
                </div>
                <div className="col-1.5 mt-2">
                    <span className='btn btn-sm btn-warning mr-2' >
                        <i className="fa fa-shopping-cart" /> Purchases
                    </span>
                </div>
                <div className="col-1.5 mt-2">
                    <span className='btn btn-sm btn-success mr-2' >
                        <i className="fa fa-file" /> Sales
                    </span>
                </div>
                <div className="col-0.5 mr-2 mt-2">
                    <span className="btn btn-sm btn-info" title="Reports">
                        <i className="fa fa-line-chart" />
                    </span>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <div className='card'>
                        <div className="row ml-0 pt-2 pb-2">
                            <div className="col-6">
                                <h4> Groups &amp; Ledgers </h4>
                            </div>
                            <div className="col-6">

                            </div>
                            <div className="col-12">
                                <hr />
                            </div>
                            <div className="col-6">
                                <span className='text-warning'>Current Assets</span>
                            </div>
                            <div className="col-6">
                                <span>0.00 Db</span>
                            </div>
                            <div className="col-12">
                                <hr />
                            </div>
                            <div className="col-6">
                                <span className='text-warning'>Fixed Assets</span>
                            </div>
                            <div className="col-6">
                                <span>0.00 Db</span>
                            </div>
                            <div className="col-12">
                                <hr />
                            </div>
                            <div className="col-6">
                                <span className='text-warning'>Equity</span>
                            </div>
                            <div className="col-6">
                                <span>0.00 Db</span>
                            </div>
                            <div className="col-12">
                                <hr />
                            </div>
                            <div className="col-6">
                                <span className='text-warning'>Long Term Liabilities</span>
                            </div>
                            <div className="col-6">
                                <span>0.00 Db</span>
                            </div>
                            <div className="col-12">
                                <hr />
                            </div>
                            <div className="col-6">
                                <span className='text-warning'>Short Term Liabilities</span>
                            </div>
                            <div className="col-6">
                                <span>0.00 Db</span>
                            </div>
                            <div className="col-12">
                                <hr />
                            </div>
                            <div className="col-6">
                                <span className='text-warning'>Direct Income</span>
                            </div>
                            <div className="col-6">
                                <span>0.00 Db</span>
                            </div>
                            <div className="col-12">
                                <hr />
                            </div>
                            <div className="col-6">
                                <span className='text-warning'>Indirect Income</span>
                            </div>
                            <div className="col-6">
                                <span>0.00 Db</span>
                            </div>
                            <div className="col-12">
                                <hr />
                            </div>
                            <div className="col-6">
                                <span className='text-warning'>Sales</span>
                            </div>
                            <div className="col-6">
                                <span>0.00 Db</span>
                            </div>
                            <div className="col-12">
                                <hr />
                            </div>
                            <div className="col-6">
                                <span className='text-warning'>Direct Expense</span>
                            </div>
                            <div className="col-6">
                                <span>0.00 Db</span>
                            </div>
                            <div className="col-12">
                                <hr />
                            </div>
                            <div className="col-6">
                                <span className='text-warning'>Indirect Expense</span>
                            </div>
                            <div className="col-6">
                                <span>0.00 Db</span>
                            </div>
                            <div className="col-12">
                                <hr />
                            </div>
                            <div className="col-6">
                                <span className='text-warning'>Purchase</span>
                            </div>
                            <div className="col-6">
                                <span>0.00 Db</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className='row'>
                        <div className='col-12 card p-3'>
                            <div className='row'>
                                <h4 className='col-8'> Favourite Ledgers </h4>
                                <span className=" col-3 btn btn-info btn-sm" ><i className="fa fa-search" /> Find Ledger </span>
                                <div className='col-12 mt-3'><span className='btn btn-sm btn-secondary'>Click <i className="fa fa-star-o" /> next to the name of a ledger to mark it as favourite. </span></div>
                            </div>
                        </div>
                        <div className='col-12 card p-3'>
                            <h4> Quick Access </h4>
                            <hr />
                            <div className='row ml-0'>
                                <div className='col-3 m-2 btn btn-sm btn-dark'><i className='fa fa-balance-scale mr-2' />Balance Sheet</div>
                                <div className='col-3 m-2 btn btn-sm btn-info'><i className='fa fa-area-chart mr-2' />Profit & Loss</div>
                                <div className='col-3 m-2 btn btn-sm btn-primary'><i className='fa fa-file mr-2' />Trial Balance</div>
                                <div className='col-3 m-2 btn btn-sm btn-danger'><i className='fa fa-fort-awesome mr-2' />GST Ledgers</div>
                                <div className='col-3 m-2 btn btn-sm btn-warning'><i className='fa fa-inr mr-2' />Reconciliation</div>
                                <div className='col-3 m-2 btn btn-sm btn-success'><i className='fa fa-th-large mr-2' />Stock Value</div>
                                <div className='col-3 m-2 btn btn-sm btn-secondary'><i className='fa fa-file-text-o mr-2' />Purchase Orders</div>
                                <div className='col-3 m-2 btn btn-sm btn-dark'><i className='fa fa-credit-card mr-2' />Credit Notes</div>
                                <div className='col-3 m-2 btn btn-sm btn-info'><i className='fa fa-money mr-2' />Debit Notes</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default Support;