import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { Post, Get } from '../../Layout/ajax';
import { CModal } from '@coreui/react';
import { useSelector, useDispatch } from "react-redux";
import { immediateToast } from "izitoast-react";
import * as Yup from "yup";
import Swal from "sweetalert2";

const validation = Yup.object().shape({
    name: Yup.string().required(),
    // code: Yup.string().required(),
    // category: Yup.string().required(),
    // subCategory: Yup.string().required(),
    // purchasePrice: Yup.number().required(),
    // salePrice: Yup.number().required(),
    // tax: Yup.number().required(),
    // minStock: Yup.number().required(),
    // maxStock: Yup.number().required(),
    // availableStock: Yup.number().required(),
    // description: Yup.string().required(),
});
const Product = () => {
    const [initValue, setinitValue] = useState({
        name: "",
        code: "",
        category: "",
        subCategory: "",
        purchasePrice: "",
        salePrice: "",
        tax: "",
        minStock: "",
        maxStock: "",
        availableStock: "",
        description: "",
        type: "Product"
    });
    const [data, setData] = useState([]);
    const [productadd, setproductadd] = useState(false);
    const [product, setproduct] = useState(0);
    const [SubCategory, setSubCategory] = useState([]);
    const [Category, setCategory] = useState([]);
    const showTost = (msg, theme) => {
        immediateToast(theme, {
            message: msg,
            timeout: 3000
        });
    }
    useEffect(() => {
        getpost(true);
    }, []);
    useEffect(() => {
        Post(`master/getSubCategorys`, { active: true })
            .then((res) => {
                setSubCategory(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        Post(`master/getCategorys`, { active: true })
            .then((res) => {
                setCategory(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }, []);
    const getpost = async (val) => {
        await Post(`master/getProduct`, { active: val })
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const handleSubmit = async (val) => {
        await Post(`master/addProduct`, val)
            .then((res) => {
                if (res.success == true) {
                    clear();
                    setproductadd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getpost(true);
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const handleEdit = async (val) => {
        val.id = product;
        await Post(`master/editProduct`, val)
            .then((res) => {
                if (res.success == true) {
                    clear();
                    setproductadd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getpost(true);
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const clear = () => {
        setinitValue({
            name: "",
            code: "",
            category: "",
            subCategory: "",
            purchasePrice: "",
            salePrice: "",
            tax: "",
            minStock: "",
            maxStock: "",
            availableStock: "",
            description: "",
            type: "Product"
        });
    }
    const get = async (val) => {
        if (val != 0) {
            Get(`master/product/${val}`)
                .then((res) => {
                    setinitValue({
                        name: res.data.Name,
                        code: res.data.Code,
                        category: res.data.Category,
                        subCategory: res.data.SubCategory,
                        purchasePrice: res.data.PurchasePrice,
                        salePrice: res.data.SalePrice,
                        tax: res.data.Tax,
                        minStock: res.data.MinStock,
                        maxStock: res.data.MaxStock,
                        availableStock: res.data.AvailableStock,
                        description: res.data.Description,
                        type: res.data.Type,
                    });
                    setproductadd(true);
                    setproduct(val);
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    }
    const deleteProduct = async (val) => {
        Swal.fire({
            title: "Are you sure want to delete ?",
            icon: "error",
            confirmButtonText: "Delete",
            confirmButtonColor: "#FD711A",
            showCancelButton: true,
            cancelButtonColor: "#0F3E6A",
        }).then(async ({ isConfirmed }) => {
            if (isConfirmed) {
                await Get(
                    `master/deleteProduct/${val}`
                )
                    .then(res => {
                        if (res.success == false) {
                            showTost(res.msg, "warning");
                        } else {
                            getpost(true);
                        }
                    })
                    .catch((err) => {
                        showTost("SOMETHING WENT WRONG ON SERVER", "error");
                    });
            }
        });
    };
    const changeStatus = async (val, status) => {
        await Post(`master/changeProductStatus`, { id: val, active: status })
            .then((res) => {
                if (res.success == true) {
                    if (status == true) {
                        getpost(false);
                        showTost("Your data has been successfully activated", "success");
                    }
                    else {
                        getpost(true);
                        showTost("Your data has been successfully deactivated", "success");
                    }
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const columns = [
        {
            name: "Type",
            selector: (row) => row.Type,
        },
        {
            name: "Name",
            selector: (row) => row.Name,
        },
        // {
        //     name: "Code",
        //     selector: (row) => row.Code,
        // },
        // {
        //     name: "Purchase Price",
        //     selector: (row) => row.PurchasePrice,
        // },
        // {
        //     name: "Sale Price",
        //     selector: (row) => row.SalePrice,
        // },
        // {
        //     name: "Available Stock",
        //     selector: (row) => row.AvailableStock,
        // },
        // {
        //     name: "Description",
        //     selector: (row) => row.Description,
        // },
        {
            name: "Status",
            selector: (row) => <input type='checkbox' value={row.is_active} checked={row.is_active == true ? "checked" : ""} onClick={() => { row.is_active == true ? changeStatus(row._id, false) : changeStatus(row._id, true) }} />,
        },
        {
            name: "",
            selector: (row) => row.is_active == true ? <><i className='btn btn-sm btn-success fa fa-pencil mr-1' onClick={() => { get(row._id); }} /><i className='btn btn-sm btn-danger fa fa-trash' onClick={() => { deleteProduct(row._id); }} /></> : "",
        },
    ];

    return (
        <div className='content-wrapper'>
            <div className="row ">
                <div className="mb-2 mt-2 row col-12">
                    <div className="col-10">
                        <h2>Product</h2>
                    </div>
                    <div className="col-2 mt-2">
                        <span className='btn btn-sm btn-warning float-right' onClick={() => { setproductadd(true); setproduct(0); }}>
                            <i className="fa fa-plus mr-2" />
                            Add Product
                        </span>
                    </div>
                    <div className="col-12">
                        <div className="custom-control custom-switch float-right">
                            <input type="checkbox" className="custom-control-input" defaultChecked id="customSwitch1" data-check_true={true} data-check_false={false} onChange={(event) => {
                                let ele = event.target;
                                let selectedId = ele.checked ? event.target.getAttribute("data-check_true") : event.target.getAttribute("data-check_false");
                                getpost(selectedId)
                            }} />
                            <label className="custom-control-label" for="customSwitch1"> Active/Inactive </label>
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-3">
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                    />
                </div>
            </div>
            <CModal alignment="center" visible={productadd} size='lg'>
                <div className="modal-header">
                    <h4 className="modal-title">{product == 0 ? "Add Product" : "Update Product"}</h4>
                    <button type="button" className="close" onClick={() => { clear(); setproductadd(false) }}>&times;</button>
                </div>
                <div className="modal-body">
                    <Formik
                        onSubmit={product == 0 ? handleSubmit : handleEdit}
                        initialValues={initValue}
                        validationSchema={validation}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div className='row'>
                                        <div className='col-12 mb-2'>
                                            <input className={`ml-2`} name="type" checked={values.type == "Product" ? true : false} value="Product" type="radio" /><span className="ml-2">Product</span>
                                            <input className={`ml-2`} name="type" checked={values.type == "Service" ? true : false} value="Service" type="radio" /><span className="ml-2">Service</span>
                                        </div>
                                        <div className='col-6 mb-2'>
                                            <label>Name</label>
                                            <input type='text' name="name" defaultValue={values.name} placeholder='Enter Name' className={!!touched.name && !!errors.name ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                        <div className='col-6 mb-2'>
                                            <label>Code</label>
                                            <input type='text' name="code" defaultValue={values.code} placeholder='Enter Code' className={!!touched.code && !!errors.code ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                        <div className='col-6 mb-2'>
                                            <label>Category</label>
                                            <select defaultValue={values.category} className={!!touched.category && !!errors.category ? "form-select is-invalid" : "form-select"} name='category'>
                                                <option value=''>Select Category</option>
                                                {Category
                                                    .map((x, i) => (
                                                        <option key={i} value={x._id}>{x.Name}</option>
                                                    ))}
                                            </select>
                                        </div>
                                        <div className='col-6 mb-2'>
                                            <label>SubCategory</label>
                                            <select defaultValue={values.subCategory} className={!!touched.subCategory && !!errors.subCategory ? "form-select is-invalid" : "form-select"} name='subCategory'>
                                                <option value=''>Select SubCategory</option>
                                                {SubCategory
                                                    .filter((x) => x.Category?._id == values.category)
                                                    .map((x, i) => (
                                                        <option key={i} value={x._id}>{x.Name}</option>
                                                    ))}
                                            </select>
                                        </div>
                                        {values.type == "Product" ? <>
                                            <div className='col-6 mb-2'>
                                                <label>Purchase Price</label>
                                                <input type='text' defaultValue={values.purchasePrice} name="purchasePrice" placeholder='Enter Purchase Price' className={!!touched.purchasePrice && !!errors.purchasePrice ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='col-6 mb-2'>
                                                <label>Sale Price</label>
                                                <input type='text' defaultValue={values.salePrice} name="salePrice" placeholder='Enter Sale Price' className={!!touched.salePrice && !!errors.salePrice ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='col-6 mb-2'>
                                                <label>Tax</label>
                                                <input type='text' defaultValue={values.tax} name="tax" placeholder='Enter Tax' className={!!touched.tax && !!errors.tax ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='col-6 mb-2'>
                                                <label>Min Stock</label>
                                                <input type='text' defaultValue={values.minStock} name="minStock" placeholder='Enter Min. Stock' className={!!touched.minStock && !!errors.minStock ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='col-6 mb-2'>
                                                <label>Max Stock</label>
                                                <input type='text' defaultValue={values.maxStock} name="maxStock" placeholder='Enter Max. Stock' className={!!touched.maxStock && !!errors.maxStock ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                            <div className='col-6 mb-2'>
                                                <label>Available Stock</label>
                                                <input type='text' defaultValue={values.availableStock} name="availableStock" placeholder='Enter Available Stock' className={!!touched.availableStock && !!errors.availableStock ? "form-control is-invalid" : "form-control"} />
                                            </div>
                                        </> : ""}
                                        <div className='col-12 mb-2'>
                                            <label>Description</label>
                                            <textarea type='text' rows="3" defaultValue={values.description} name="description" placeholder='Enter Description' className={!!touched.description && !!errors.description ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                        <div className='col-12'>
                                            <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </CModal>
        </div >
    );
}

export default Product;