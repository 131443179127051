import './App.css';
import DefaultLayout from './Layout/DefaultLayout';

function App() {
  return (
    <div className="wrapper">
      <DefaultLayout />
    </div>
  );
}

export default App;
