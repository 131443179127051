import { createReducer } from "@reduxjs/toolkit";
import { Get } from "../../Layout/ajax";

let initialState = { Modules: [] };
if (JSON.parse(window.localStorage.getItem("Role")) !== null) {
    await Get(`ModuleRight/GetModuleRightByRole/${JSON.parse(window.localStorage.getItem("Role"))}`)
        .then((res) => {
            if (res.data) {
                initialState.Modules = res.data
            }
            else if (res.message == "Authorization Failure!"){
                window.localStorage.removeItem("Id");
                window.localStorage.removeItem("Token");
                window.localStorage.removeItem("Email");
                window.localStorage.removeItem("Role");
                window.localStorage.removeItem("RoleName");
                window.localStorage.removeItem("Name");
                window.location.reload();
                window.location.replace("/Login")
            }
    })
        .catch((err) => {
            // showTost("SOMETHING WENT WRONG ON SERVER", "warning");
        });
}

export const RoleRight = createReducer(initialState, {
    setRoleRightModule: (state, action) => {
        let modulename = action?.payload?.modulename;
        let read = action?.payload?.read;
        let write = action?.payload?.write;
        let delete1 = action?.payload?.delete;
        if (modulename) {
            let objIndex = state.Modules.findIndex(f => f.modulename == modulename);
            state.Modules[objIndex].read = read;
            state.Modules[objIndex].write = write;
            state.Modules[objIndex].delete = delete1;
        }
    },
    setAllModuleRight: (state, action) => {
        state.Modules = action.payload;
    }
})