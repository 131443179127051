import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { saveAs } from 'file-saver';
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PDFViewer = ({ blob }) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [pdfUrl, setPdfUrl] = useState(null);


    useEffect(() => {
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            let base64String = reader.result;
            setPdfUrl(base64String.substr(base64String.indexOf(',') + 1));
        };
    }, [blob]);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const downloadPDF = () => {
        saveAs(blob, 'document.pdf');
    };

    return (
        <div>
            <Document file={`data:application/pdf;base64,${pdfUrl}`}
                onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} renderTextLayer={false} />
            </Document>
            <div>
                <p>
                    Page {pageNumber} of {numPages}
                </p>
                <button onClick={() => setPageNumber(pageNumber - 1)} disabled={pageNumber <= 1}>
                    Previous
                </button>
                <button onClick={() => setPageNumber(pageNumber + 1)} disabled={pageNumber >= numPages}>
                    Next
                </button>
                <button onClick={downloadPDF}>Download PDF</button>
            </div>
        </div>
    );
};

export default PDFViewer;
