import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { Post, Get } from '../../Layout/ajax';
import { CModal } from '@coreui/react';
import * as Yup from "yup";
import { Link, useParams } from 'react-router-dom';
import Swal from "sweetalert2";
import { immediateToast } from "izitoast-react";

const validation = Yup.object().shape({
    address: Yup.string().required(),
    city: Yup.string().required(),
    state: Yup.string().required(),
    country: Yup.string().required(),
});
const Customeraddress = () => {
    const [ShippingAddress, setShippingAddress] = useState([]);
    const [Shippingid, setShippingid] = useState(0);
    const [BillingAddress, setBillingAddress] = useState([]);
    const [State, setState] = useState([]);
    const [Country, setCountry] = useState([]);
    const [City, setCity] = useState([]);
    const [Billingid, setBillingid] = useState(0);
    const [CustomerName, setCustomerName] = useState(null);
    const [ShippingAddressadd, setShippingAddressadd] = useState(false);
    const [BillingAddressadd, setBillingAddressadd] = useState(false);
    const { id } = useParams();
    useEffect(() => {
        getpost();
        Post(`master/getCountry`, { active: true })
            .then((res) => {
                setCountry(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        Post(`master/getState`, { active: true })
            .then((res) => {
                setState(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        Post(`master/getCity`, { active: true })
            .then((res) => {
                setCity(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }, []);
    const showTost = (msg, theme) => {
        immediateToast(theme, {
            message: msg,
            timeout: 3000
        });
    }
    const getpost = async () => {
        Get(`customer/getCustomerById/${id}`)
            .then((res) => {
                setShippingAddress(res.data[0]?.ShippingAddress)
                setBillingAddress(res.data[0]?.BillingAddress)
                setCustomerName(res.data[0]?.FirstName + "  " + res.data[0]?.LastName)
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const [BillinginitValue, setBillinginitValue] = useState({
        customerId: id,
        address: "",
        city: "",
        state: "",
        country: "",
    });
    const [ShippinginitValue, setShippinginitValue] = useState({
        customerId: id,
        address: "",
        city: "",
        state: "",
        country: "",
    });
    const Billingclear = () => {
        setBillinginitValue({
            customerId: id,
            address: "",
            city: "",
            state: "",
            country: "",
        });
    }
    const Shippingclear = () => {
        setShippinginitValue({
            customerId: id,
            address: "",
            city: "",
            state: "",
            country: "",
        });
    }
    const Billingget = async (val) => {
        let Billingobj = BillingAddress.find(f => f._id == val);
        if (Billingobj) {
            setBillinginitValue({
                customerId: Billingobj.Customer,
                address: Billingobj.Address,
                city: Billingobj.City?._id,
                state: Billingobj.State?._id,
                country: Billingobj.Country?._id,
            });
            setBillingAddressadd(true);
            setBillingid(val);
        }
    }
    const Shippingget = async (val) => {
        let Shippingobj = ShippingAddress.find(f => f._id == val);
        if (Shippingobj) {
            setShippinginitValue({
                customerId: Shippingobj.Customer,
                address: Shippingobj.Address,
                city: Shippingobj.City?._id,
                state: Shippingobj.State?._id,
                country: Shippingobj.Country?._id,
            });
            setShippingAddressadd(true);
            setShippingid(val);
        }
    }
    const handleSubmitBilling = async (val) => {
        if (Billingid == 0) {
            await Post(`customer/addBillingAddress`, val)
                .then((res) => {
                    if (res.success == false) {
                        showTost(res.msg, "warning");
                    }
                    else {
                        Shippingclear();
                        setBillingAddressadd(false);
                        getpost();
                        showTost("Your data has been Successfully added", "success");
                    }
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
        else {
            val.id = Billingid;
            await Post(`customer/editBillingAddress`, val)
                .then((res) => {
                    if (res.success == false) {
                        showTost(res.msg, "warning");
                    }
                    else {
                        Shippingclear();
                        setBillingAddressadd(false);
                        getpost();
                        showTost("Your data has been Successfully Updated", "success");
                    }
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    };
    const handleSubmitShipping = async (val) => {
        if (Shippingid == 0) {
            await Post(`customer/addShippingAddress`, val)
                .then((res) => {
                    if (res.success == false) {
                        showTost(res.msg, "warning");
                    }
                    else {
                        Shippingclear();
                        setShippingAddressadd(false);
                        getpost();
                        showTost("Your data has been Successfully added", "success");
                    }
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
        else {
            val.id = Shippingid;
            await Post(`customer/editShippingAddress`, val)
                .then((res) => {
                    if (res.success == false) {
                        showTost(res.msg, "warning");
                    }
                    else {
                        Shippingclear();
                        setShippingAddressadd(false);
                        getpost();
                        showTost("Your data has been Successfully Updated", "success");
                    }
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        }
    };
    const DefaultAddress = async (addid, val) => {
        var valnew = {}
        valnew.customerId = id;
        valnew.addressId = addid;
        valnew.type = val;
        valnew.default = true;
        await Post(`customer/setDefaultAddress`, valnew)
            .then((res) => {
                if (res.success == false) {
                    showTost(res.msg, "warning");
                }
                else {
                    getpost();
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const removeShipping = async (val) => {
        Swal.fire({
            title: "Are you sure want to delete ?",
            icon: "error",
            confirmButtonText: "Delete",
            confirmButtonColor: "#FD711A",
            showCancelButton: true,
            cancelButtonColor: "#0F3E6A",
        }).then(async ({ isConfirmed }) => {
            if (isConfirmed) {
                await Post(
                    `customer/removeShippingAddress/${val}`
                )
                    .then(res => {
                        if (res.success == false) {
                            showTost(res.msg, "warning");
                        } else {
                            getpost();
                        }
                    })
                    .catch((err) => {
                        showTost("SOMETHING WENT WRONG ON SERVER", "error");
                    });
            }
        });
    };
    const removeBilling = async (val) => {
        Swal.fire({
            title: "Are you sure want to delete ?",
            icon: "error",
            confirmButtonText: "Delete",
            confirmButtonColor: "#FD711A",
            showCancelButton: true,
            cancelButtonColor: "#0F3E6A",
        }).then(async ({ isConfirmed }) => {
            if (isConfirmed) {
                await Post(
                    `customer/removeBillingAddress/${val}`
                )
                    .then(res => {
                        if (res.success == false) {
                            showTost(res.msg, "warning");
                        } else {
                            getpost();
                        }
                    })
                    .catch((err) => {
                        showTost("SOMETHING WENT WRONG ON SERVER", "error");
                    });
            }
        });
    };
    const BillingAddresscolumns = [
        {
            name: "",
            selector: (row) => <><i className='fa fa-pencil btn btn-sm btn-success mr-2' onClick={() => { Billingget(row._id); }} /><i className='fa fa-trash btn btn-sm btn-danger' onClick={() => { removeBilling(row._id); }} /></>,
        },
        {
            name: "Address",
            selector: (row) => row.Address,
        },
        {
            name: "Country",
            selector: (row) => row.Country?.Name,
        },
        {
            name: "State",
            selector: (row) => row.State?.Name,
        },
        {
            name: "City",
            selector: (row) => row.City?.Name,
        },
        {
            name: "Default",
            selector: (row) => <input type='checkbox' checked={row.is_default == true ? "checked" : ""} onClick={() => DefaultAddress(row._id, "billing")} />,
        },
    ];
    const ShippingAddresscolumns = [
        {
            name: "",
            selector: (row) => <><i className='fa fa-pencil btn btn-sm btn-success mr-2' onClick={() => { Shippingget(row._id); }} /><i className='fa fa-trash btn btn-sm btn-danger' onClick={() => { removeShipping(row._id); }} /></>,
        },
        {
            name: "Address",
            selector: (row) => row.Address,
        },
        {
            name: "Country",
            selector: (row) => row.Country?.Name,
        },
        {
            name: "State",
            selector: (row) => row.State?.Name,
        },
        {
            name: "City",
            selector: (row) => row.City?.Name,
        },
        {
            name: "Default",
            selector: (row) => <input type='checkbox' checked={row.is_default == true ? "checked" : ""} onClick={() => DefaultAddress(row._id, "shipping")} />,
        },
    ];
    return (
        <div className='content-wrapper'>
            <div className="mb-2 mt-2">
                <Link to={'/Customer'}><i className="fa fa-arrow-left text-dark" /></Link>
                <h2>Customer Shipping/Billing Address</h2>
                <h4>Customer Name : {CustomerName}</h4>
            </div>
            <div className="row ">
                <div className="col-6 mt-3">
                    <span className='btn btn-sm btn-warning mb-2 float-right' onClick={() => { setShippingAddressadd(true); setShippingid(0); }}>
                        <i className="fa fa-plus mr-2" />
                        Add Shipping Address
                    </span>
                    <DataTable
                        columns={ShippingAddresscolumns}
                        data={ShippingAddress}
                    />
                </div>
                <div className="col-6 mt-3">
                    <span className='btn btn-sm btn-warning mb-2 float-right' onClick={() => { setBillingAddressadd(true); setBillingid(0); }}>
                        <i className="fa fa-plus mr-2" />
                        Add Billing Address
                    </span>
                    <DataTable
                        columns={BillingAddresscolumns}
                        data={BillingAddress}
                    />
                </div>
            </div>
            <CModal alignment="center" visible={BillingAddressadd}>
                <div className="modal-header">
                    <h4 className="modal-title">{Billingid == 0 ? "Add Billing Address" : "Update Billing Address"}</h4>
                    <button type="button" className="close" onClick={() => { Billingclear(); setBillingAddressadd(false); }}>&times;</button>
                </div>
                <div className="modal-body">
                    <Formik
                        onSubmit={handleSubmitBilling}
                        initialValues={BillinginitValue}
                        validationSchema={validation}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div className='mb-2'>
                                        <label>Address</label>
                                        <input type='text' name="address" defaultValue={values.address} placeholder='Enter Address' className={!!touched.address && !!errors.address ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className="mb-2">
                                        <label>Country</label>
                                        <select defaultValue={values.country} className={!!touched.country && !!errors.country ? "form-select is-invalid" : "form-select"} name='country'>
                                            <option>Select Country</option>
                                            {
                                                Country.map((x, i) => (
                                                    <option key={i} value={x._id}>{x.Name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="mb-2">
                                        <label>State</label>
                                        <select defaultValue={values.state} className={!!touched.state && !!errors.state ? "form-select is-invalid" : "form-select"} name='state'>
                                            <option>Select State</option>
                                            {State.filter((x) => x.Country?._id == values.country).map((x, i) => (
                                                <option key={i} value={x._id}>{x.Name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="mb-2">
                                        <label>City</label>
                                        <select name='city' defaultValue={values.city} className={!!touched.city && !!errors.city ? "form-control is-invalid" : "form-control"}>
                                            <option>Select City</option>
                                            {
                                                City.filter((x) => x.State?._id == values.state).map((x, i) => (
                                                    <option key={i} value={x._id}>{x.Name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className='mt-2'>
                                        <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </CModal>
            <CModal alignment="center" visible={ShippingAddressadd}>
                <div className="modal-header">
                    <h4 className="modal-title">{Shippingid == 0 ? "Add Shipping Address" : "Update Shipping Address"}</h4>
                    <button type="button" className="close" onClick={() => { Shippingclear(); setShippingAddressadd(false); }}>&times;</button>
                </div>
                <div className="modal-body">
                    <Formik
                        onSubmit={handleSubmitShipping}
                        initialValues={ShippinginitValue}
                        validationSchema={validation}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors,
                            setFieldValue,
                        }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={handleChange}>
                                    <div className='mb-2'>
                                        <label>Address</label>
                                        <input type='text' name="address" defaultValue={values.address} placeholder='Enter Address' className={!!touched.address && !!errors.address ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className="mb-2">
                                        <label>Country</label>
                                        <select defaultValue={values.country} className={!!touched.country && !!errors.country ? "form-select is-invalid" : "form-select"} name='country'>
                                            <option>Select Country</option>
                                            {
                                                Country.map((x, i) => (
                                                    <option key={i} value={x._id}>{x.Name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="mb-2">
                                        <label>State</label>
                                        <select defaultValue={values.state} className={!!touched.state && !!errors.state ? "form-select is-invalid" : "form-select"} name='state'>
                                            <option>Select State</option>
                                            {State.filter((x) => x.Country?._id == values.country).map((x, i) => (
                                                <option key={i} value={x._id}>{x.Name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="mb-2">
                                        <label>City</label>
                                        <select name='city' defaultValue={values.city} className={!!touched.city && !!errors.city ? "form-control is-invalid" : "form-control"}>
                                            <option>Select City</option>
                                            {
                                                City.filter((x) => x.State?._id == values.state).map((x, i) => (
                                                    <option key={i} value={x._id}>{x.Name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className='mt-2'>
                                        <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </CModal>
        </div >
    );
}

export default Customeraddress;